/** 
	IMPORTANT: These styles ONLY apply for screen widths
	BETWEEN the first and second break-point 
	
	Media query is not needed inside this file
**/

footer {
	padding-bottom: 15px;
	padding-top: 15px;
	.region-pre-footer1 {
		width: 92vw;
		position: relative;
		right: calc(46vw - 50%);
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		.block-nodeblock {
			width: 48%;
			padding: 15px;
			margin-bottom: 10px;
			&.block-newsletter {
				.content {
					width: 100%;
					input.form-submit {
						font-size: 15px;
					}
				}
			}
		}
	}
	.pre_footer1 {
		.block.block-klantenservice {
			.field-item {
				p:nth-of-type(2) {
					font-size: 27px;
				}
			}
		}
	}

	.footer {
		.region-footer {
			display: flex;

			justify-content: space-around;
			.block-copyright {
				margin-top: 5px;
			}
		}
	}
}

.front {
	.region-highlited {
		.block.block-views {
			.view-content {
				display: flex;
				flex-wrap: wrap;
				width: 92vw;
				position: relative;
				right: calc(46vw - 50%);
				justify-content: center;
				.views-row {
					width: 48%;
					padding: 10px;
				}
			}
		}
	}
}

.page-giftcards {
	&.page-giftcards-extra {
		#edit-right>h2 {
			font-size: 25px;
			margin-bottom: 25px;
			margin-top: 0px;
		}
		.view-wrappers.view-id-wrappers,
		.view-wines.view-id-wines {
			.view-content {
				display: flex;
				flex-wrap: wrap;
				width: 92vw;
				position: relative;
				right: calc(46vw - 50%);
				justify-content: space-around;
				.views-row {
					width: 48%;
					padding: 10px;
					.field-name-title {
						p.last-child {
							text-align: center;
						}
					}
				}
			}
		}
		.view-wines.view-id-wines {
			.field-name-title {
				p.last-child {
					text-align: left!important;
				}
			}
		}
	}
	&.page-giftcards-overview {
		.block-system {
			form {
				.form-actions {
					padding-bottom: 10px;
				}
			}
			#edit-reference {
				h4 {
					margin-bottom: 15px;
				}
				a {
					margin-top: 15px;
				}
			}
			#edit-payment {
				.header {
					margin-bottom: 15px;
				}
			}
		}
	}
}

.block.block-ndb-big .right li {
	font-size: 17px;
	margin-bottom: 10px;
}

#navbar #main-menu .region-menu #block-giftcard-form-giftcard-small-cart a:after {
	left: 0px;
}
