@import 'default.scss';

.default-font-family {
	font-family: $font-family;
}
.default-font {
	@extend .default-font-family;
	font-size: $font-size;
	color: $black;
	line-height: 1.428571429;
	font-weight: $normal;
}

.default-font-line-height {
	line-height: 26px;
}

.default-font-weight-semibold {
	font-weight: 600;
}

.default-font-weight-bold {
	font-weight: 700;
}

body {
	background-color: $white;
	overflow: hidden;
}
.ui-widget,
body,
input,
select,
textarea {
	@extend .default-font;
}
a {
	color: $black;
	text-decoration: none;
	transition: color ease-in-out 0.15s, text-decoration ease-in-out 0.15s, background-color ease-in-out 0.15s;
	&:visited {
		@extend a;
	}
	&:active,
	&:hover {
		text-decoration: underline;
	}
}

.button {
	display: inline-block;
	font-size: 18px;
	font-weight: 600;
	color: $white;
	padding: 6px 24px;
	background: lighten($black, 30%);
	transition: background .3s;
	border-radius: 8px;
	text-align: center;
}

.button-green {
	@extend .button;
	background: #17c51b;

    &:hover {
		background: darken(#17c51b, 10%);
	}
}

.button-pink {
	@extend .button;
	background: #e83659;

	&:hover {
		background: #c82646;
	}
}

.button-blue {
	@extend .button;
	background: $color7;

	&:hover {
		background: lighten($color7, 10%);
	}
}

.button-red {
	@extend .button;
	background: $color3;

	&:hover {
		background: lighten($color3, 10%);
	}
}

a.button {
	&, &:visited {
		color: $white;
	}

	&:hover {
		text-decoration: none;
	}
}

// p,
// p span {
// 	letter-spacing: -0.035em;
// 	* {
// 		letter-spacing: 0;
// 	}
// }

ul li {
	list-style-type: none;
	list-style-image: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: $heavy;
	line-height: 1.3em;
	color: $color3;

	&.node-title,
	&.node-title a {
		color: $black;
	}

	a {
		color: $black;
		&:hover {
			text-decoration: none;
		}
	}
}

h1 {
	margin-bottom: 16px;
	margin-left: -2px;
	font-size: 24px;
}

h2,
h3,
h4,
h5,
h6 {
	font-size: 20px;
}

.container {
	position: relative;
}

ul.menu {
	margin: 0;

	li.leaf {
		list-style-image: none;
	    list-style-type: none;
	    margin: 0;
	    padding: 0;
	}
}


@import 'main_menu.scss';

#page {
	position: relative;
}

.highlited-wrapper {
	.not-front & {
		background: $color4;
		@extend .clearfix;
	}

	.page-giftcards & {
		padding: 7px 0px;
	}
}

.node {
	.region-highlited & {
		.field-item {
			div {
				&:nth-child(1) {
					display: none;
				}

				&:nth-child(2) {
					img {
						display: block;
						width: 100%;
						margin: 0 auto;
					}

					a {
						display: inline-block;
					}
				}

				&:nth-child(2),
				&:nth-child(3),
				&:nth-child(4),
				&:nth-child(5),
				&:nth-child(6) {
					max-width: 314px;
					margin-left: auto;
					margin-right: auto;
				}

				&:nth-child(3) {
					margin-top: 8px;

					.button {
						display: block;
						font-size: 20px;
						font-weight: 600;
						text-transform: uppercase;
						letter-spacing: 2px;
						padding: 8px 24px;
					}
				}

				&:nth-child(4) {
					text-align: center;
					margin-top: 16px;
					border: 1px solid lighten($grey, 5%);
					border-radius: 4px;
					padding: 12px 10px;

					span {
						display: none;
					}

					img {
						vertical-align: middle;
						margin: 0 5px;
					}
				}

				&:nth-child(5),
				&:nth-child(6) {
					margin-top: 10px;
					font-size: 20px;
					font-size: 17px;
					padding-left: 10px;
					padding-right: 10px;

					p {
						padding-left: 25px;
						position: relative;
						&:after {
							content: '';
							position: absolute;
							left: 0;
							top: 5px;
							@include sprite($nxte-vink);
						}
					}
				}
			}
		}
	}

	&.node-page {
		.not-front & {
			.field-name-title {
				display: none;
			}

			.field-name-body {
				h2 {
					font-size: 26px;
				}

				h3 {
					font-size: 20px;
					font-weight: $bold;
				}

				h2, h3 {
					margin-bottom: 10px;
				}
			}
		}

		&.node-page-full {
			> .group-left,
			> .group-right {
				width: 100%
			}

			.block-ndb-small {
				@extend .block.block-ndb-small;
				padding: 25px;
				background: $light-pink;
				border-radius: 8px;
			}
		}
	}

	&.node-restaurant {
		&.node-teaser {
			margin-bottom: 38px;

			.field-name-title {
				h2 {
					a {
						font-size: 26px;
						color: $color3;
					}
				}
			}

			.field-name-field-image-main {
				margin: 22px 0;

				img {
					border: none;
					border-radius: 20px;
				}
			}

			.field-name-adres {
				.field-label,
				.field-items,
				.field-item {
					display: inline;
				}

				.field-label {
					color: $color3;
				}
			}

			.field-name-node-link {
				margin-top: 22px;

				a {
					@extend .button;
					@extend .button-red;
					text-transform: uppercase;
					font-size: $font-size;
				}
			}
		}
	}
}



footer {
	background: $color3;
	color: $color2;
	position: relative;
	padding-bottom: 30px;
	padding-top: 40px;

	a {
		color: $color2;
	}

	.pre_footer1 {

		h2 {
			margin-bottom: 14px;
			color: $color2;
		}

		.node-webform {
			.field-name-body {
				.field-item {
					& > div {
						@extend .footer-title;
					}
				}
			}
		}

		.block {
			margin-bottom: 40px;

			.content {
				width: 248px;
			}

			&.block-newsletter {
				form {
					padding-top: 10px;

					.sticky-placeholder-wrapper {
						input {
							border-radius: 8px;
							border: none;
							padding: 8px 12px;
							height: auto;
						}

						label {
							color: $black;
							font-style: italic;
							opacity: 1;
							transition: opacity .3s;

							&.sticky-placeholder-label-focus {
								opacity: .6
							}
						}
					}

					.webform-component--e-mail {
						margin-bottom: 8px;
					}

					.form-actions {
						margin: 0;

						input {
							display: inline-block;
							width: 100%;
							background: transparent;
							border: 1px solid $white;
							border-radius: 8px;
							font-weight: $semibold;
							color: $white;
							transition: color .3s, background .3s;
							padding: 3px 0;
							text-transform: uppercase;

							&:hover {
								background: $white;
								color: $black;
							}
						}
					}
				}
			}

			&.block-contact {
				.field-item {

					& > div {
						@extend .footer-title;
					}
					p {
						&:nth-of-type(1) {
							margin-bottom: 4px;
						}

						&:nth-of-type(2) {
							margin-bottom: 27px;
						}

						&:nth-of-type(3) {
							margin-bottom: 6px;
						}
					}
				}
			}

			&.block-klantenservice {
				.field-item {
					& > div {
						@extend .footer-title;
					}

					p {
						&:nth-of-type(1),
						&:nth-of-type(2) {
							margin-bottom: 0;
						}

						&:nth-of-type(2) {
							font-size: 30px;
						}

						&:nth-of-type(4) {
							a {
								padding-left: 20px;
								position: relative;
								&:after {
									content: "";
									display: block;
									position: absolute;
									left: 0;
									top: 4px;
									@include sprite($nxte-envelop);
								}
							}
						}
					}
				}
			}
		}
	}

	.pre_footer2 {
		display: none;

		h2 {
			color: $color2;
		}

		.field-name-body {
			.field-item {
				& > div {
					@extend .footer-title;
				}
			}
		}
	}

	.footer {
		.block-footer-payments {
			margin-bottom: 15px;

			.field-item {
				p {
					img {
						&:first-child {
							height: 22px;
							width: auto;
						}

						vertical-align: middle;
						margin-right: 6px;

						&:last-child {
							margin-right: 0;
						}
					}
				}
			}
		}

		.block-copyright {
			.field {
				font-size: 12px;
			}
		}
	}
}

.block {
	.front .region-highlited & {
		&:first-child {
			margin-bottom: 40px;
		}
	}

	&.block-views {
		.front .region-highlited & {
			background: $color1;
			position: relative;

			&:before {
				background-image: url("../images/front-rest-top.png");
				top: -14px;
			}

			&:after {
				background-image: url("../images/front-rest-bot.png");
				bottom: -14px;
			}

			.content {
				width: $container-width-xs;
				margin-left: auto;
				margin-right: auto;
			}

			.view {
				padding: 50px 0;
				position: relative;
			}

			.view-header {
				padding-bottom: 30px;

				p {
					font-size: 25px;
					font-weight: $heavy;
					color: $color2;
					text-align: center;
				}
			}

			.view-content {
				position: relative;
				z-index: 1;

				.views-row {
					margin-bottom: 36px;
					&:last-child {
						margin-bottom: 0;
					}
				}

				.field-name-field-image-main {
					a {
						display: block;
						line-height: 0;
					}

					img {
						border-radius: 8px;
					}
				}

				.field-name-title p {
					margin: 0;

					a {
						font-size: 20px;
						font-weight: 900;
						line-height: 26px;

						&,
						&:hover,
						&:focus,
						&:active {
							text-decoration: none;
						}


					}

				}

				.field-name-title a,
				.field-name-body {
					color: $color2;
				}

				.field-name-body {
					a {
						color: $color3;
						text-decoration: underline;
						&:hover { text-decoration: none; }
					}
				}

				.field-name-field-image-main,
				.field-name-title,
				.field-name-body {
					margin-bottom: 20px;
				}

				.field-name-node-link {
					a {
						@extend .button-green;
					}
				}
			}
		}
	}

	&.block-system {
		.front .region-content & {
			padding: 40px 0;

			.field-name-title {
				display: none;
			}

			.field-name-body {
				h2, h3 {
					margin-bottom: 20px;
					font-size: 22px;
				}

				p {
				    line-height: 1.92;
				}

				div {
					margin-bottom: 20px;
				}
			}
		}

		.node-type-restaurant & {
			.ds-2col {
				.group-left,
				.group-right {
					float: none;
					width: auto;
					@include clearfix;
				}

				.group-right {
					margin-top: 20px;
					@extend .default-font-line-height;
				}
			}

			.field-name-title {
				h1 {
					font-size: 26px;
				}
			}

			.group-restaurant-info {
				border: 1px solid $color3;
				padding: 30px 25px;
				margin-bottom: 30px;
				border-radius: 10px;
			}

			.field-name-adres,
			.field-name-field-telephone {
				padding-top: 2px;
				margin-bottom: 28px;

				.field-label {
					font-size: 20px;
				}
			}

			.field-name-field-toon-link {
				a {
					@extend .default-font-weight-semibold;
					color: $green-light;
					text-decoration: underline;

					&:hover {
						text-decoration: none;
					}
				}
			}

			.field-name-field-image,
			.field-name-field-head-image-detailed {
				a {
					display: block;
					line-height: 0;
					position: relative;
					margin-bottom: 10px;
					border: 1px solid $color3;
					border-radius: 10px;

					&:before {
						content: "";
						display: block;
						width: 100%;
						height: 100%;
						position: absolute;
						top: 0;
						left: 0;
						opacity: 0;
						background: rgba($black, .5);
						transition: opacity .3s;
						border-radius: 8px;
					}

					&:after {
						content: "";
						display: block;
						width: 34px;
						height: 34px;
						background: url("../images/plus-icon.png") no-repeat center center;
						opacity: 0;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						transition: opacity .3s;
					}

					&:hover {
						&:before,
						&:after {
							opacity: 1;
						}
					}
				}

				img {
					border-radius: 8px;
					width: 100%;
				}
			}

			.field-name-field-image {
				.field-item {
					float: left;
					width: 48%;

					&:nth-child(2n) {
						margin-left: 4%;
					}
				}
			}
		}

		.contact-page & {
			.field-name-title {
				margin-bottom: 25px;

				h2 {
					font-size: 36px;
				}
			}

			.field-name-body {
				margin-bottom: 20px;
				@extend .default-font-line-height;

				a {
					@extend .default-font-weight-bold;
				}
			}

			form {
				.form-item {
					.field-prefix {
						display: none;
						@extend .default-font-weight-bold;
					}

					.sticky-placeholder-wrapper {
						input,
						textarea {
							border: 1px solid $color3;
						}

						label {
							font-style: italic;
						}
					}

					&.webform-component-textarea {
						> label {
							display: none;
						}

						.sticky-placeholder-wrapper {
							label {
								&:after {
									content: "*";
									display: inline;
									color: #f00;
									margin-left: 3px;
								}
							}
						}
					}
				}

				.form-actions {
					input {
						@extend .button;
						@extend .button-red;
						line-height: normal;
					}
				}

				.mollom-privacy {
					a {
						font-weight: bold;
					}
				}
			}

			.mollom-privacy {
				clear: both;
			}
		}

		.saldocheck-page & {
			iframe {
				border: 0;
				height: 170px;
			}
		}

		.zakelijk-page & {
			border-bottom: 1px solid $grey;
		    padding-bottom: 30px;

			.delivery-date-zakelijk {
				.gratis,
				.shipping-date {
					font-size: 14px;
				}

				.gratis {
					color: $fifth-color;
					font-weight: bold;
				}

				.shipping-date {
					padding-left: 15px;
					font-weight: $heavy;
					position: relative;
					&:after {
						content: '';
						position: absolute;
						left: 0;
						top: 5px;
						@include sprite($nxte-clock);
					}
				}
			}
		}
	}

	&.block-phone-guy {
		border-bottom: 1px solid $grey;

		.field-item {
			h2 {
				font-size: 30px;
				font-weight: 600;
				margin-bottom: 15px;
			}

			p:last-child {
				text-align: center;
				line-height: 0;
			}
		}
	}

	&.block-saldocheck-paycard {
		.field-item {
			h2, h3 {
				color: $color3;
				font-weight: $bold;
				margin-top: 15px;
			}

			.left {
				ul {
					margin: 30px 0;
				}

				li {
					@extend .default-font-weight-bold;
					padding-left: 10px;
					margin-bottom: 5px;

					&:before {
						content: ">";
						margin-left: -10px;
						margin-right: 5px;
					}
				}
			}

			p {
				a {
					@extend .default-font-weight-bold;
				}
			}
		}
	}

	&.block-page-title,
	.not-front .highlited-wrapper & {
		.content {
			@extend .container;
		}

		.field-name-body {
			// padding: 10px 0;
			padding: 15px 0 5px 0;
		}

		h1,
		h2 {
			font-size: 20px;
			margin-bottom: 0;
			margin-left: 0;
		}
	}

	&.block-overons-side {
		.field-name-body {
			h2 {
				margin-bottom: 10px;
				font-weight: $bold;
			}

			p {
				img {
					width: 100%;
				}
			}
		}
	}

	&.block-ndb-small {
		.zakelijk-page & {
			clear: left;
		}

		.content {
			padding: 25px;
			background: $color4;
			border-radius: 8px;
		}

		ul {
			margin: 0;

			li {
				font-size: 16px;
				font-weight: $bold;
				padding-left: 25px;
				margin-bottom: 20px;
				position: relative;
				&:after {
					content: '';
					position: absolute;
					left: 0;
					top: 2px;
					@include sprite($nxte-vink);
				}
			}
		}

		.button {
			font-size: 20px;
			text-transform: uppercase;
			padding: 5px 22px;
			font-size: 18px;
		}
	}

	&.block-ndb-big {
		.content {
			padding: 30px;
			background: $color4;
			border-radius: 10px;
		}

		.left,
		.right,
		.betaal {
			margin-bottom: 15px;
		}

		.right {
			ul {
				margin: 0;
			}

			li {
				padding-left: 25px;
				font-size: 18px;
				font-weight: $bold;
				margin-bottom: 15px;
				position: relative;
				&:after {
					content: '';
					position: absolute;
					left: 0;
					top: 2px;
					@include sprite($nxte-vink);
				}
				&:last-child { margin-bottom: 0; }
			}
		}

		.betaal {
			border: 1px solid $color6;
			padding: 10px;
			border-radius: 4px;
			text-align: center;

			div {
				&:nth-child(1) {
					span {
						font-size: 18px;
						font-weight: $bold;
						line-height: 2;
						display: block;
					}

					img {
						vertical-align: middle;
						margin-left: 5px;
						&:nth-child(2) {
							margin-left: 0;
						}
					}
				}

				&:nth-child(2) {
					padding: 10px 0;

					img {
						vertical-align: middle;
					}

					p {
						position: relative;

						&:before,
						&:after {
							content: "";
							display: block;
							position: absolute;
							height: 1px;
							width: calc(50% - 15px);
							top: 50%;
							background: $color6;
						}

						&:before {
							left: 0;
						}

						&:after {
							right: 0;
						}
					}
				}

				&:nth-child(3) {
					font-size: 12px;
					font-weight: $normal;

					span {
						color: $green-light;
					}
				}
			}
		}

		.btn {
			.button {
				width: 100%;
				text-transform: uppercase;
			}
		}
	}

	&.block-load-more {
		.field-item {
			text-align: center;
			position: relative;

			a {
				background: $white;
				color: $grey;
				font-size: 18px;
				padding: 6px 25px;
				border: 1px solid $grey;
				border-radius: 4px;
				font-weight: $semibold;
				transition: color .3s, border-color .3s;
				display: inline-block;

				& + span {
					position: absolute;
					height: 1px;
					width: 200%;
					background: $grey;
					top: 20px;
					left: 50%;
					transform: translateX(-50%);
					transition: background .3s;
					display: block;
					z-index: -1;
				}

				&:hover {
					text-decoration: none;
					color: $color3;
					border-color: $color3;

					& + span {
						background: $color3;
					}
				}
			}
		}
	}

	&.block-nxte-map-search {
		&#block-nxte-map-search-nxte-map-search-companies-map {
			height: 450px;
		}

		&#block-nxte-map-search-nxte-map-search-search-form {
			margin-bottom: 20px;

			h1 {
				font-size: 36px;
				margin-bottom: 15px;

				& + p span {
					font-weight: $semibold !important;
					font-size: 18px !important;
					background: none !important
				}
			}

			#edit-container {
				.form-wrapper {
					margin-bottom: 10px;

					.form-item {
						margin: 0;
					}
				}
			}

			#edit-left {
				.sticky-placeholder-wrapper {
					input {
						border-color: $color3;
						padding-left: 30px;
					}

					label {
						font-style: italic;
						color: $color3;
						opacity: 1;
						top: 1 !important;
						padding-top: 8px !important;

						&.sticky-placeholder-label-focus {
							opacity: .6;
						}
					}
				}

				input[type="submit"] {
					left: 10px;
					top: 12px !important;
					@include sprite($nxte-search-icon)
				}
			}

			#edit-right {
				.ui-selectmenu {
					border-color: $color3;
					color: $color3;
					font-weight: $normal;
					background: none;
					height: auto;
					width: 100% !important;

					&:hover {
						background: none;
						text-decoration: none;
					}

					span {
						&:first-child {
							padding: 10px 14px 9px 30px;
						}

						&:last-child {
							left: 8px;
							margin-top: -6px;
							width: 15px;
							height: 11px;
							background-image: unset;
							&:after {
								content: '';
								position: absolute;
								top: 0;
								left: 0;
								@include sprite($nxte-arrow-down);
							}
						}
					}
				}
			}
		}
	}

	&.block-contact-contacts {
		.content {
			background: $color4;
			border-radius: 10px;
			padding: 30px;
		}

		.field-item {
			h2,
			& > div:nth-of-type(1) {
				font-size: 26px;
				font-weight: 900;
				line-height: 1.3em;
				color: $color3;
			}

			h3,
			& > div:not(:nth-of-type(1)) {
				font-size: 14px;
				font-weight: $bold;
				color: $color1;
				margin-top: 14px;
			}

			p {
				margin-bottom: 30px;

				&:nth-of-type(2) {
					margin-bottom: 0;
				}
			}
		}
	}

	&.block-contact-map {
		.content {
			overflow: hidden;
		}

		iframe {
			border-radius: 10px;
			height: 160px;
		}
	}

	&.block-giftcard-breadcrumbs {
		.content {
			@include clearfix;
			padding: 23px 0;
			text-align: center;

			div {
				display: inline-block;
				font-size: 18px;
				@extend .default-font-weight-semibold;
				margin-right: 14px;
				position: relative;
				height: 34px;

				&:after {
					content: "";
					display: block;
					width: 16px;
					height: 3px;
					background: $black;
					position: absolute;
					left: 100%;
					top: 50%;
				}

				&:last-child {
					margin-right: 0;
					&:after {
						display: none;
					}
				}

				.text,
				.arrow {
					display: none;
				}

				.number {
					display: inline-block;
					width: 33px;
					height: 34px;
					text-align: center;
					font-size: 24px;
					overflow: hidden;
					text-indent: 101%;
					position: relative;
					&:after {
						content: '';
						position: absolute;
						left: 0;
						top: 0;
						@include sprite($nxte-bc-frame);
					}
					&:before {
						content: '';
						position: absolute;
						left: 9px;
						top: 10px;
						@include sprite($nxte-check);
					}
				}

				&.current ~ div {
					.number {
						text-indent: 0;
						&:before {
							content: none;
						}
					}
				}
			}
		}
	}

	&.block-zakelijk-popup {
		display: none;

		.node {
			max-width: 644px;
		}

		.field-name-title {
			display: none;
		}

		form.webform-client-form {
			border: 0;
			padding: 0;

			.form-item {
				.form-text,
				.form-textarea {
					border-color: $color3;
				}
			}

			.webform-component--vul-onderstande {
				margin-top: 0;

				h2 {
					font-size: 30px;
					color: $color1;
				}
			}

			.webform-component-textfield,
			.webform-component-email,
			.webform-component-textarea {
				span {
					label {
						width: auto !important;
						height: auto !important;
					}
				}
			}

			.webform-component--kunnen {
				h2 {
					font-size: 24px;
					margin-bottom: 15px;
				}

				strong {
					font-size: 38px;
					margin-right: 10px;
				}

				img {
					vertical-align: -8px;
					margin: 6px;
				}
			}

			.form-actions {
				input {
					@extend .button-green;
				    padding: 3px 30px;
				}
			}
		}
	}

	&.block-zakelijk-slider {
		.field-name-title {
			h1, h2 {
				font-size: 30px;
			}
		}

		.field-name-field-slider {
			position: relative;
			margin: 15px 0;

			.field-slideshow-wrapper {
				background: $grey;
				border-radius: 8px;
			    overflow: hidden;
			}

			.field-slideshow {
				margin-left: auto;
				margin-right: auto;

				.field-slideshow-slide {
					a,
					img {
						display: block;
					}
				}
			}

			.field-slideshow-controls {
				a {
					position: absolute;
					display: block;
					visibility: hidden;
					width: 13px;
					height: 22px;
					text-indent: 50px;
					overflow: hidden;
					top: 50%;
					transform: translateY(-50%);
					background-repeat: no-repeat;
					opacity: 0;
					transition: left .3s, right .3s;
					z-index: 10;

					&:first-child {
						left: 0px;
						background-image: url("../images/slider-arrows.png");
					}

					&:last-child {
						right: 0px;
						background-image: url("../images/slider-arrows.png");
						background-position: -18px 0;
					}
				}
			}

			&:hover {
				.field-slideshow-controls {
					a {
						visibility: visible;
						opacity: 1;

						&:first-child {
							left: 10px;

							&:hover {
								background-position: 0 -28px;
							}
						}

						&:last-child {
							right: 10px;

							&:hover {
								background-position: -18px -28px;
							}
						}
					}
				}
			}
		}

		.field-name-body {
			h2 {
				font-size: 18px;
				font-weight: $bold;
				color: $color1;
			}

			p {
				&.last-child {
					a {
						height: auto;
						&:after {
							top: 10px;
						}
					}
				}
			}
		}
	}

	&.block-front-header {
		@extend .container;
		margin-bottom: 40px;
		text-align: center;

		h1 {
			font-size: 26px;
		}
	}
}

.breadcrumbs {
	.page-giftcards & {
		display: none;
	}

	padding-top: 5px;
	padding-bottom: 15px;

	.content {
		@extend .container;
	}

	ol {
		list-style: none;
		margin: 0;
		@include clearfix;

		li {
			float: left;
			font-size: 14px;
			color: $color3;

			a {
				color: $color5;
				transition: color .3s;

				&:after {
					content: ">";
					font-weight: bold;
					margin: 0 4px;
				}

				&:hover {
					color: $color3;
					text-decoration: none;
				}
			}
		}
	}
}

.region {
	&.region-content {
		.not-front & {
			padding: 40px 0;

			.block {
				margin-bottom: 40px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		.not-front.page-search-results & {
			padding-top: 0;
		}

		.not-front.node-type-restaurant & {
			padding-top: 20px;
		}

		.saldocheck-page & {
			.node.node-webform {
				.field-name-title {
					h2 {
						font-size: 36px;
						margin-bottom: 15px;
					}
				}
				form {
					.webform-component {
						> label {
							&, & span {
								display: none !important;
							}
						}

						&.webform-component-date .sticky-placeholder-wrapper input,
						.sticky-placeholder-wrapper {
							width: 100%;
						}

						.sticky-placeholder-wrapper {
							label {
								font-style: italic;
							}

							input {
								border: 1px solid $color3;
							}
						}

						&.webform-component-textfield,
						&.webform-component-email {
							.sticky-placeholder-wrapper {
								label {
									&:after {
										content: "*";
										display: inline-block;
										color: #FF0000;
										margin-left: 3px;
									}
								}
							}
						}

						&.webform-component-date {
							.description {
								margin-top: 15px;
							}
						}

						&.webform-component-checkboxes {
							font-size: 16px;

							a {
								color: $black;
								font-weight: bold;
							}
						}
					}

					.form-actions {
						input {
							@extend .button;
							@extend .button-red;
							font-weight: bold;
						}
					}
				}
			}
		}
	}
}

.backlink {
	margin-top: 30px;
    text-align: right;

	.node-type-restaurant & {
		a {
			@extend .button;
			@extend .button-red;
			font-size: $font-size;
			text-transform: uppercase;
		}
	}
}

#modalBackdrop {
	background: $black !important;
}

#modalContent {
	box-shadow: 0 0 40px 15px rgba(0,0,0,0.5);
	position: fixed !important;
	top: 50% !important;
	left: 50% !important;
	transform: translate(-50%, -50%);
    border-radius: 10px;

	.ctools-modal-content {
		margin: 0;
		border: none;
		padding: 40px;
		position: relative;
		width: auto !important;
		border-radius: 10px;
	}

	.modal-header {
		background: none;

		#modal-title {
			display: none;
		}

		.close {
			display: block;
			width: 21px;
			height: 22px;
			overflow: hidden;
			text-indent: 30px;
			background: url("../images/close-btn.png") no-repeat center center;
			float: none;
			position: absolute;
			top: 18px;
			right: 18px;

			img {
				display: none;
			}
		}
	}

	#modal-content {
		padding: 0;
		height: 100% !important;
		max-width: 644px !important;

		.resizable-textarea {
			margin: 0;
			width: 100%;
		}

		.webform-component--vul-onderstande {
			margin-bottom: 20px;

			h2 {
				font-size: 30px;
			}
		}

		.webform-component--kunnen {
			h2 {
				font-size: 24px;
				color: $golden;
			}

			p {

				strong {
					font-size: 38px;
				}

				img {
					vertical-align: -6px;
					margin-right: 10px;
				}
			}
		}

		.form-actions {
			clear: left;

			input {
				@extend .button;
				@extend .button-green;
			}
		}
	}
}

.page-restaurants {
	.region-content {
		.block-nxte-gmap {
			#nxte-gmap-container {
				height: 220px;
				border-radius: 20px;
			}
		}

		.view-footer {
			text-align: center;
		}

		.pager {
			.pager-previous,
			.pager-next {
				display: block;
				margin-left: 0;
			}

			li {
				&.pager-item {
					padding: 0;

					a {
						padding: 4px 8px;
						border: 1px solid transparent;
						border-radius: 8px;

						&:hover {
							text-decoration: none;
							border-color: $golden;
						}
					}
				}

				&.pager-current {
					padding: 4px 8px;
					border: 1px solid $golden;
					border-radius: 8px;
				}
			}
		}
	}

	.views-exposed-form {
		.views-exposed-widgets {
			margin-bottom: 20px;

			.views-widget-per-page {
				label {
					color: $black;
					line-height: 36px;
				}

				span {
					a {
						width: 62px !important;
						background: no-repeat;
						border-color: $golden;

						&:hover {
							text-decoration: none;
						}
					}
				}
			}

			.views-submit-button {
				input {
					@extend .button;
					@extend .button-red;
					line-height: normal;
					margin-top: 0;
				}
			}
		}
	}

	.ui-selectmenu-menu {
		ul {
			min-width: 62px !important;
			border-color: $golden;
		}
	}

	#block-nxte-location-view-restaurant-search {
		margin-bottom: 20px;

		h1 {
			font-size: 44px;
			margin-bottom: 15px;

			& + p span {
				font-weight: $semibold !important;
				font-size: 16px !important;
				background: none !important
			}
		}

		#edit-container {
			.form-wrapper {
				margin-bottom: 10px;

				.form-item {
					margin: 0;
				}
			}
		}

		#edit-left {
			position: relative;

			.sticky-placeholder-wrapper {
				input {
					border-color: $golden;
					padding-left: 30px;
				}

				label {
					font-style: italic;
					color: $black;
					opacity: 1;
					top: 1 !important;
					padding-top: 8px !important;

					&.sticky-placeholder-label-focus {
						opacity: .6;
					}
				}
			}

			input[type="submit"] {
				background-image: url("../images/search-icon.png") !important;
				width: 13px;
				height: 16px;
				left: 10px;
				top: 12px;
				position: absolute;
				background-color: transparent;
				text-indent: 20px;
			}
		}

		#edit-right {
			.ui-selectmenu {
				border-color: $golden;
				background: none;
				height: auto;
				width: 100% !important;

				&:hover {
					background: none;
					text-decoration: none;
				}

				span {
					&:first-child {
						padding: 8px 14px 8px 30px;
					}

					&:last-child {
						left: 8px;
						margin-top: -6px;
						width: 15px;
						height: 11px;
						background-position: 0 0;
						background-image: url("../images/arrow-down.png");						
					}
				}
			}
		}
	}
} // .page-restaurants

.page-giftcards {
	.ajax-progress-throbber {
		display: none !important;
	}

	.block-giftcard-ndb {
		.field-item {
			h1, h2 {
				font-size: 26px;
				margin-left: 0;
			}
		}
	}

	.block-system {

		.giftcard-intro {
			margin-bottom: 50px;
			@include clearfix;

			.left {
				text-align: center;
				margin-bottom: 15px;

				img {
					max-width: 264px;
				}
			}

			.right {
				p {
					margin-bottom: 30px;
					line-height: 1.8;
				}

				ul {
					margin: 0;

					li {
						font-weight: $bold;

						&:before {
							content: "•";
							margin-right: 5px;
						}
					}
				}
			}
		}

		#edit-delivery {
			margin-top: 20px;

			.delivery,
			.delivery-date {
				font-weight: bold;
				background: $color3;
				color: $color2;
				padding: 7px 10px;
				display: inline-block;
				width: 100%;
			}

			.delivery {
				// border-top-left-radius: 5px;
				// border-bottom-left-radius: 5px;
			}

			.delivery-date {
				min-width: 137px;
				text-transform: lowercase;
				// border-top-right-radius: 5px;
				// border-bottom-right-radius: 5px;
			}

			.container-inline-date {
				visibility: hidden;
				position: absolute;
				z-index: -10;
			}

			a {
				display: block;
				color: $color3;
				margin-top: 15px;
				cursor: pointer;
				font-weight: bold;

				&:before {
					content: "»";
					margin-right: 6px;
				}
			}
		}

		.paymanet-summery {
			background: $color4;
			padding: 30px;
			border-radius: 10px;
			margin-top: 26px;

			h3 {
				font-size: 36px;
				font-weight: bold;
			}

			.field-title,
			.back-nav {
				font-weight: bold;
				margin-top: 20px;
			}

			.field-body {
				p {
					margin-bottom: 0;
					line-height: 1.5;
				}
			}

			.back-nav {
				a {
					color: $color3;

					&:before {
						content: "»";
						margin-right: 6px;
					}
				}
			}
		}

		#edit-cart {
			border: 1px solid $color3;
			border-radius: 10px;
			padding: 25px 20px;
			margin-top: 26px;

			.giftcard-cart {
				h3 {
					font-size: 26px;
					margin-bottom: 25px;
					position: relative;
					padding-left: 50px;
					&:before {
						content: "";
						display: inline-block;
						width: 24px;
						height: 22px;
						position: absolute;
						left: 0;
						margin-right: 10px;
						@include sprite($nxte-winkelwagen);
					 	transform-origin: 0% 10%;
						transform: scale(0.65);
					}
				}

				.item-list {
					ul {
						margin-bottom: 0;
					}

					li {
						margin-left: 0;
						position: relative;
					}

					.title {
						font-weight: normal;
					}

					.price {
						position: absolute;
						right: 0;
						top: 22px;
						min-width: 60px;
					}

					a {
						position: absolute;
						display: block;
						width: 11px;
						height: 11px;
						overflow: hidden;
						text-indent: 101%;
						right: 0;
						top: 2px;
						&:after {
							content: '';
							position: absolute;
							left: 0;
							top: 0;
							@include sprite($nxte-close-icon);
						}
					}
				}
			}

			.giftcard-pane-totals {
				label {
					color: $black;
				}

				span {
					float: right;
					display: inline-block;
					min-width: 60px;
				}

				.subtotal {
					border-bottom: 1px solid $black;
					padding-bottom: 20px;
					margin-bottom: 10px;
				}

				.total,
				.subtotal {
					&, & label { font-weight: bold; }
				}

				.total {
					border-top: 1px solid $black;
					padding-top: 20px;
					margin-top: 10px;
				}
			}
		}

		form {
			.form-actions {
				background: $color4;
				border-radius: 10px;
				padding: 10px;
				text-align: right;
			    transform: translateY(16px);

				input {
					@extend .button;
					@extend .button-green;
					padding: 2px 24px;
				}
			}

			.giftcard-txt1 {
				margin-bottom: 20px;

				h2 {
					font-size: 24px;
				}

				h3, p:nth-of-type(1) {
					font-size: 22px;
					color: $color3;
					font-weight: $bold;
					margin-bottom: 0;
				}
			}

			position: relative;

			> div > a {
				position: absolute;
				bottom: 4px;
				z-index: 1;
				left: 10px;
				color: $color3;
				transition: color .3s;
				font-size: 14px;
				font-weight: bold;

				&:before {
					content: "«";
					display: inline;
					margin-right: 5px;
				}

				&:hover {
					color: $black;
				}
			}
		}

		.giftcard-pane-cart-sidebar {
			.block-giftcard-ssl {
				border: 1px solid $color3;
				border-radius: 10px;
				padding: 15px;
				margin-top: 26px;

				p {
					font-size: 12px;
					font-weight: bold;

					img {
						margin-right: 5px;
					    vertical-align: sub;
					}

					span {
						color: $green;
					}
				}
			}

			.block-giftcard-review {
				background: $color4;
				border-radius: 10px;
				padding: 20px;
				margin-top: 26px;
				position: relative;

				.recommend {
					font-size: 20px;
					color: $color3;
				}

				.stars-wrapper,
				.review-stars-wrapper {
					@include clearfix;

					.star-wrapper,
					.review-star-wrapper {
						float: left;
						width: 30px;
						height: 27px;
						position: relative;
						margin-left: 5px;

						&:first-child {
							margin-left: 0;
						}

						.star-image,
						.review-star-image {
							position: absolute;
						}

						.star-bg,
						.review-star-bg {
							position: absolute;
							background: url("../images/star.png") no-repeat left center;
							height: 100%;
							top: 0;
						}
					}
				}

				.stars-wrapper {
					margin-top: 10px;
				}

				.review-stars-wrapper {
					.review-star-wrapper {
						width: 15px;
						height: 13px;
						margin-left: 3px;

						.review-star-bg {
							background-size: auto 100%;
						}
					}
				}

				.separateScore {
					margin-left: 6px;
					span {
						font-size: 14px;
						font-weight: bold;
					}
				}

				.review-stars-wrapper,
				.separateScore {
					display: inline-block;
				}

				.count {
					margin-top: 10px;

					a {
						line-height: 22px;
						background: url("../images/bubbles.png") no-repeat left center;
						padding-left: 36px;
						color: $grey;
						transition: color .3s;
						font-size: 14px;

						&:hover {
							color: $black;
						}
					}
				}

				.score {
					width: 78px;
					margin: 0 auto;
					position: relative;

					span {
						font-weight: bold;

						&:nth-child(1) {
							font-size: 76px;
						}

						&:nth-child(2),
						&:nth-child(3) {
							font-size: 36px;
						}

						&:nth-child(4) {
							font-size: 18px;
							color: $grey;
						}

						&:nth-child(2),
						&:nth-child(3),
						&:nth-child(4) {
							position: absolute;
						}

						&:nth-child(2) {
							top: 16px;
							right: 26px;
						}

						&:nth-child(3) {
							top: 14px;
							right: 0;
						}

						&:nth-child(4) {
							right: 6px;
							bottom: 22px;
						}
					}
				}

				.review-author {
					span {
						font-size: 14px;
						font-weight: bold;
						margin-left: 5px;

						&:first-child {
							margin-left: 0;
						}
					}
				}
			}

			.block-giftcard-payment {
				margin-top: 26px;
				p {
					padding-left: 30px;
					font-weight: $bold;
					font-size: 16px;
					position: relative;
					&:after {
						content: '';
						position: absolute;
						left: 0;
						top: 1px;
						@include sprite($nxte-vink);
					}

					&:nth-child(4) {
						&:after {
							content: '';
							position: absolute;
							left: 0;
							top: 1px;
							@include sprite($nxte-postnl);
						}
					}

					&:last-child {
						padding-left: 0;
						background: none;

						img {
							vertical-align: middle;
							margin-left: 6px;

							&:first-child {
								height: 22px;
								width: auto;
								margin-left: 0;
							}
						}
						&:after {
							content: none;
						}
					}
				}
			}
		}

		.giftcard-pane-right {
			.giftcard-pane-right-middle {
				border: 1px solid $color3;
				border-radius: 10px;
				padding: 20px;

				.ajax-progress-throbber {
					display: none !important;
				}

				.form-item-giftcard-amount {
					border: 1px solid $black;
					border-radius: 4px;
					width: 164px;
					height: 38px;
					margin-left: auto;
					position: relative;

					&:before {
						content: "";
						display: block;
						width: 61px;
						height: 40px;
						left: -64px;
						top: -2px;
						position: absolute;
						@include sprite($nxte-wijn);
					}

					label {
						width: 100px;
						line-height: 36px;
						margin-right: 0;
						text-align: center;
						border-right: 1px solid $black;
					}

					.ui-selectmenu {
						background: none;
						border: none;
						width: 61px !important;
						height: 36px;
						margin-left: -3px;
						text-decoration: none;

						span {
							&:first-child {
								text-align: left;
								font-weight: bold;
								line-height: 1.8em;
							}
						}
					}
				}

				.form-item-giftcard-sell-price {
					vertical-align: bottom;

					.field-prefix {
						display: none;
					}

					> label {
						margin-right: 0;
						max-width: 154px;
					}

					.sticky-placeholder-wrapper {
						width: 70px;
						height: 38px;

						input {
							border-color: $black;
							border-radius: 4px;
							height: 36px;
						}

						label {
							margin-right: 0;
							top: 0 !important;
							padding: 0 12px !important;
							line-height: 36px !important;
							opacity: 1;
						}
					}
				}

				div:last-child {
					input {
						@extend .button;
						@extend .button-green;
					    padding: 1px 24px;
					}
				}
			}

			.giftcard-pane-right-right {
				#edit-top {
					.form-item-giftcard-form-extra-include-message {
						&:before {
							content: "";
							display: inline-block;
							width: 17px;
							height: 17px;
							background: url("../images/note.png") no-repeat left top;
						}

						.icheckbox {
							display: none !important;
						}
					}

					.form-item-s-remark {
						display: block !important;

						.counter {
							display: none !important;
						}

						textarea {
							border: 1px solid $grey;

							&.focus {
								border-color: $green;
							}
						}
					}

					.extra-services {
						font-size: 24px;
						color: $golden;
						font-weight: bold;
					}

					.form-item-giftcard-form-extra-product {
						border: 1px solid $grey;
						padding: 10px 20px;
						border-radius: 10px;
						margin-bottom: 0;
						min-height: 96px;

						.form-item-giftcard-form-extra-product-1 {
							position: relative;
							@include clearfix;

							&:before {
								content: "";
								display: block;
								width: 29px;
								height: 32px;
								background: url("../images/vines.jpg") no-repeat center center;
								position: absolute;
								top: 5px;
								left: 22px;
							}

							.icheckbox {
								margin-top: 15px;
							}

							label {
								position: absolute;
								display: inline-block;
								margin-right: 0;
								width: 100%;
								top: 0;
								left: 0;
								padding-left: 60px;

								span {
									font-size: 16px;
									color: $black;
								}
							}
						}
					}

					.extra-products {
						border: 1px solid $grey;
						border-top: 0;
						background: $white;
						margin-top: -10px;
						border-bottom-left-radius: 4px;
						border-bottom-right-radius: 4px;

						.giftcard-form-extra-product {
							background: none;
							margin-right: 0;
							padding: 10px 20px;
							height: auto !important;

							.giftcard-form-extra-product-left {
								width: 50px;
							}

							.giftcard-form-extra-product-right {
								width: 168px;
								padding-top: 0;

								.product-title {
									font-size: 16px;
									color: $black;
								}

								.product-price {
									position: static;
									font-size: 16px;
									margin: 20px 0;
								}

								a {
									position: static;
									background-image: none;
									box-shadow: none;
									@extend .button;
									@extend .button-red;
									border-radius: 4px;
									width: auto;
									height: auto;
								    padding: 3px 24px;
								}
							}
						}
					}
				}

				#edit-bottom {
					.form-item-giftcard-wrapper {
						margin-top: 26px;

						#edit-giftcard-wrapper {
							border: 1px solid $grey;
							border-radius: 10px;
							padding: 11px 20px;
							position: relative;
							min-height: 92px;

							&:before {
								content: "";
								display: block;
								width: 59px;
								height: 51px;
								background: url("../images/giftbox.jpg") no-repeat center center;
								position: absolute;
								left: 42px;
							    top: 8px;
							}

							.form-item {
								margin: 0;
							}

							.icheckbox {
								margin-top: 20px;
							}

							p {
								display: inline;
								color: $black;
								font-size: 16px;
							}

							label {
								position: absolute;
								width: 100%;
								padding-left: 110px;
								height: 100%;
								left: 0;
								top: 0;
								padding-top: 12px;
							}
						}
					}
				}
			}
		}
	}

	.ui-selectmenu-menu {
		ul {
			min-width: 64px !important;
			border-color: $color1;
		}
	}
}

.page-giftcards-extra {
	.block-system {
		form {
			@extend .clearfix;
		}
	}

	#edit-right {
		@include clearfix;

		> h2 {
			font-size: 20px;
			margin-bottom: 40px;
		}

		.view {
			width: 100%;

			.node {
				width: 250px;
				padding: 20px 12px 15px;
				position: relative;
				padding-bottom: 65px;
				margin: 0 auto 10px;

				&:hover {
					box-shadow: 0 0 20px -5px $color1;

					[id^=edit-ammount-wrapper] {
						display: block;
					}
				}

				[id^=edit-ammount-wrapper] {
					position: absolute;
					width: 100%;
					left: 0;
					bottom: 15px;
					padding: 0 12px;
					display: none;

					> div {
						display: inline-block;
						border: 1px solid $color3;
						border-radius: 8px;
						height: 39px;
						width: 85px;
						line-height: 37px;

						&, & input {
							text-align: center;
							font-size: 20px;
						}

						a, div {
							display: inline-block;
							margin: 0;
						}

						a {
							text-decoration: none;
							width: 22px;

							&:last-child {
								margin-left: -6px;
							}
						}

						.sticky-placeholder-wrapper {
							vertical-align: top;

							input {
								height: 37px !important;
								width: 39px !important;
								border-radius: 0;
								border-top: 0;
								border-bottom: 0;
								border-color: $color3;
								padding: 0;
								font-weight: $bold;
							}
						}
					}

					input[type=submit] {
						margin-left: 11px;
						vertical-align: top;
					    padding: 2px 17px;
						@extend .button;
						@extend .button-red;
						width: 106px;
					}
				}

				.field-name-uc-product-image {
					.field-label {
						display: none;
					}

					img {
						display: block;
					}
				} // .field-name-uc-product-image

				.field-name-title {
					h2,
          .field-item > div {
						font-size: 14px;
						font-weight: $bold;
						color: $color1;
            text-align: center;
					}
				} // .field-name-title

				.product-info {
					font-size: 20px;
					font-weight: $bold;

					.uc-price-label {
						display: none;
					}

					.uc-price {
						color: $green-light;
					}
				} // .product-info

			} // .node

			&.view-wrappers {
				.node {
					padding-bottom: 0;

					&:hover {
						.field-name-title,
						.product-info {
							visibility: hidden;
						}
					}

					.field-name-uc-product-image {
						padding-bottom: 15px;
						margin-bottom: 15px;
					} // .field-name-uc-product-image

					.field-name-title {
						h2 {
							text-align: center;
						}
					} // .field-name-title

					.product-info {
						text-align: center;
					} // .product-info
				}
			} // &.view-wrappers

			&.view-wines {
				.node {
					@include clearfix;
					padding-bottom: 15px;

					.field-name-uc-product-image {
						float: left;
						width: 50px;
					} // .field-name-uc-product-image

					.field-name-title,
					.field-name-body,
					.product-info {
						float: right;
						width: 176px;
					}

					.field-name-title {
						h2,
            .field-item > div {
							font-size: 20px;
							font-weight: $light;
              text-align: left;
						}
					}

					.field-name-body {
						margin: 30px 0;
					} // .field-name-body

					.product-info {
						text-align: left;
					} // .product-info

					[id^=edit-ammount-wrapper] {
						position: static;
						display: block;
						visibility: hidden;
						clear: both;
						padding-top: 25px;
					}

					&:hover {
						[id^=edit-ammount-wrapper] {
							visibility: visible;
						}
					}
				} // .node
			}
		} // .view

		.extra-message {
		    margin-top: 15px;

			span {
				display: block;

				&:last-child {
					font-weight: $heavy;
					font-size: 20px;
					margin-top: 25px;
					color: $green-light;
				}
			}
		} // .extra-message

		.form-item-s-remark {
			textarea {
				height: 120px;
				border: 1px solid $color3;
			}

			.counter {
				display: none;
			}
		} // .form-item-s-remark

		.add-remark {
			clear: both;
			float: right;
			display: block;
			@extend .button;
			@extend .button-green;
		}
	} // #edit-right

	.sidebar-next-button {
		text-align: right;
		margin-top: 20px;

		a {
			@extend .button;
			@extend .button-green;
		}
	}
} // end .page-giftcards-extra

.page-giftcards-shipping {
	.block-system {
		.giftcard-pane-right {
			#edit-billing {
				h4 {
					font-size: 24px;
					margin-bottom: 20px;
				}

				.form-item {
					&.form-type-checkbox {
						.icheckbox {
							border-color: $black;
						}

						label {
							color: $black;
							font-weight: bold;
						}
					}
				}

				.prefix {
					display: none;
				}

				.sticky-placeholder-wrapper {
					input {
						border-color: $color3;
					}

					label {
						opacity: 1;
						color: $black;
						font-style: italic;
						transition: opacity .3s;

						&.sticky-placeholder-label-focus {
							opacity: .6;
						}
					}
				}
			}

			#giftcard-newsletter-remark {
				label {
					color: $black;

					p {
						display: inline;
					}
				}

				.icheckbox {
					border-color: $black;
				}
			}

			#edit-shipping {
				.form-type-checkbox {
					label {
						color: $black;
					}

					.icheckbox {
						border-color: $black;
					}
				}

				.personal-pane-left {
					border-top: 1px solid $grey;
					padding-top: 30px;
					margin-top: 20px;

					h4 {
						font-size: 24px;
						margin-bottom: 20px;
					}

					.prefix {
						display: none;
					}

					.sticky-placeholder-wrapper {
						input {
							border-color: $color3;
						}

						label {
							opacity: 1;
							color: $black;
							font-style: italic;
							transition: opacity .3s;

							&.sticky-placeholder-label-focus {
								opacity: .6;
							}
						}
					}
				}
			}
		}
	}
}

.page-giftcards-payment {
	.block-system {
		.giftcard-pane-right {
			.payment-method {
				h4 {
					font-size: 24px;
					margin-bottom: 20px;
				}
			}

			.form-item-payment-method {
				.form-item {
					label {
						color: $black;
						padding-left: 56px;
						line-height: 26px;
						display: inline-block;
						max-width: 243px;
						position: relative;
					}

					.iradio {
						border-color: $black;
						position: relative;
						top: 4px;
					}

					&:nth-child(1) {
						label {
							&:before {
								content: '';
								position: absolute;
								left: 14px;
								top: 2px;
								@include sprite($nxte-ideal);
							}
						}
					}

					&:nth-child(2) {
						label {
							&:before {
								content: '';
								position: absolute;
								left: -2px;
								top: 5px;
								@include sprite($nxte-visa);
								transform: scale(0.9);
							}
						}
					}

					&:nth-child(3) {
						label {
							&:before {
								content: '';
								position: absolute;
								left: 4px;
								top: 1px;
								@include sprite($nxte-mastercard);
							}
						}
					}

					&:nth-child(4) {
						label {
							&:before {
								content: '';
								position: absolute;
								left: 16px;
								top: 0px;
								@include sprite($nxte-opfactuur);
							}
						}
					}
				}
			}

			.payment_footer_text {
				color: $grey;
				font-style: italic;
				margin-top: 20px;
				max-width: 490px;
			}
		}

		.giftcard-pane-cart-sidebar {}
	}
}

.page-giftcards-overview {
	.block-system {
		#edit-shipping {
			border-bottom: 1px solid $grey;
			padding-bottom: 26px;

			.paymanet-summery {
				background: none;
				padding: 0;

				h3 {
					font-size: 24px;
				}
			}

			> a {
				display: inline-block;
				margin-top: 25px;
				color: $golden;
				font-weight: bold;

				&:before {
					content: "»";
					display: inline-block;
					margin-right: 10px;
				}
			}
		}

		#edit-payment {
			margin-top: 26px;
			border-bottom: 1px solid $grey;
			padding-bottom: 26px;

			.header {
				font-weight: bold;
				font-size: 24px;
				margin-bottom: 30px;
			}

			p.last-child {
				line-height: 30px;
				padding-left: 45px;
				background-repeat: no-repeat;
				background-position: left center;

				&.ideal {
					position: relative;
					&:before {
						content: '';
						position: absolute;
						left: 0px;
						top: 4px;
						@include sprite($nxte-ideal);
					}
				}

				&.visa {
					position: relative;
					&:before {
						content: '';
						position: absolute;
					    left: -9px;
						top: 7px;
						@include sprite($nxte-visa);
					    transform: scale(0.7);
					}

				}

				&.mastercard {
					position: relative;
					&:before {
						content: '';
						position: absolute;
				        left: 0px;
						top: 2px;
						@include sprite($nxte-mastercard);
						transform: scale(0.95);
					}
				}

				&.paymentguarantee {
					background-image: url("../images/achteraf.png");
				}

				&.invoice {
					position: relative;
					&:before {
						content: '';
						position: absolute;
						left: 16px;
						top: 0px;
						@include sprite($nxte-opfactuur);
					}
				}
			}

			a {
				display: inline-block;
				margin-top: 25px;
				color: $color3;
				font-weight: bold;

				&:before {
					content: "»";
					display: inline-block;
					margin-right: 10px;
				}
			}
		}

		#edit-reference {
			margin-top: 26px;
			border-bottom: 1px solid $grey;
			padding-bottom: 26px;

			h4 {
				font-size: 24px;
				margin-bottom: 30px;
			}

			.reference,
			.header {
				display: inline;
				p {
					display: inline;
					margin-left: 5px;
				}
			}

			a {
				display: inline-block;
				margin-top: 25px;
				color: $color3;
				font-weight: bold;

				&:before {
					content: "»";
					display: inline-block;
					margin-right: 10px;
				}
			}

			div:nth-child(2) {
				margin-bottom: 26px;
			}
		}

		#edit-cart {
			border: none;
			padding: 0;
			background: none;
			border-bottom: 1px solid $grey;
			padding-bottom: 26px;
		}

		.delivery-date-title {
			font-size: 24px;
			font-weight: bold;
			margin-top: 26px;
			margin-bottom: 30px;
		}

		#edit-delivery {
			border-bottom: 1px solid $grey;
			padding-bottom: 26px;
		}

		#edit-right {
			> p {
				margin-top: 26px;
				color: $grey;
				font-style: italic;

				a {
					font-weight: bold;
					color: $grey;
				}
			}
		}
	}
}

#ui-datepicker-div {
	z-index: 10 !important;

	.ui-state-default,
	.ui-state-disabled {
		text-align: center;
	    background: $white;

	    &.ui-state-active {
	    	background: $color3;
	    	color: $white
	    }
	}
}

.zakelijk-popup-container {
	.fancybox-skin {
		background: $color2;
		color: $color1;
		padding: 40px !important;
		border-radius: 10px;
	}

	.fancybox-close {
		background: url("../images/close-btn.png") no-repeat center center;
		width: 21px;
		height: 22px;
		top: 18px;
		right: 18px;
	}
}

.webform-component--aanmeldformulier {
	& > p {
		font-size: 20px;
		font-weight: 900;
		color: $color3;
		margin: 0;
		line-height: 26px;
	}
}

@import 'grid_custom.scss';

/*  Thuiswinkel logo
	just delete this when you need it back*/

.block {
	.footer & {
		&.block-footer-payments {
			.field-item {
				img:first-child {
					display: none !important;
				}
			}
		}
	}

	#navbar & {
		&.block-tuisvinkel {
			display: none !important;
		}
	}
}

.page-giftcards .block-system .giftcard-pane-cart-sidebar .block-giftcard-payment p:last-child img:first-child {
	display: none;
}

._isOverlayed {
  overflow: hidden;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

#goToTop {
	width: 40px;
	height: 40px;
	position: fixed;
	bottom: 20px;
	right: 20px;
	text-decoration: none;
	display: none;
	z-index: 9999;
	opacity: .5;
	transition: opacity 0.3s ease-in-out;
	&:hover {
		opacity: 1;
	}
	&:after {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		@include sprite($nxte-arrow-up-green);
		transform: scale(0.3);
		transform-origin: 0 0;
	}
}


#navbar.fixed {
	.logo {
		a {
			margin-top: 0px;
		}
	}
}

