.col {
	width: $column-width;
	padding: 0px floor($gutter-width / 2) 0px ceil($gutter-width / 2);
	float: left;
	vertical-align: top;
}

.row {
 	margin-left: (-1 * floor($gutter-width / 2));
	margin-right: (-1 * ceil($gutter-width / 2));
}

.container {
	width: $container-width-xs;
	margin: 0px auto;
}

body {

	.grid-overlay {
		position: fixed;
		top: 0px;
		left: 0px;
		height: 100%;
		width: 100%;
		z-index: 9999;
		opacity: 0.3;
		pointer-events: none;
		display: none;

		div {
			background: linear-gradient(left, red $column-content-width, transparent $gutter-width);
			background-size: $column-width 1px;
			background-repeat: repeat;
			margin: 0px auto;
			height: 100%;
		}

	}

	#debug-toggle-wrapper {
		position: fixed;
		top: 40px;
		right: 5px;
		z-index: 99999;
		width: 23px;

		#debug-toggle {
			height: 22px;
			cursor: pointer;
			background-image: url('../images/debug-toggle.png');

			&:after {
				content: "xs";
				text-transform: uppercase;
				border: 1px solid #000;
				color: #000;
				display: block;
				width: 100%;
				position: relative;
				top: 25px;
				left: 0px;
				text-align: center;
			}

		}

	}

	&.toolbar {

		#debug-toggle-wrapper {
			top: 110px;
		}

	}

	&.display-debug {

		.grid-overlay{
			display: block;
		}

	}

}

.visible-xs, .visible-xs-block, .visible-xs-inline, .visible-xs-inline-block,
.visible-sm, .visible-sm-block, .visible-sm-inline, .visible-sm-inline-block,
.visible-md, .visible-md-block, .visible-md-inline, .visible-md-inline-block,
.visible-lg, .visible-lg-block, .visible-lg-inline, .visible-lg-inline-block,
.visible-xl, .visible-xl-block, .visible-xl-inline, .visible-xl-inline-block {
	display: none !important;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-13, .col-14, .col-15, .col-16, .col-17, .col-18, .col-19, .col-20,
.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-13, .col-xs-14, .col-xs-15, .col-xs-16, .col-xs-17, .col-xs-18, .col-xs-19, .col-xs-20,
.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-13, .col-sm-14, .col-sm-15, .col-sm-16, .col-sm-17, .col-sm-18, .col-sm-19, .col-sm-20,
.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-13, .col-md-14, .col-md-15, .col-md-16, .col-md-17, .col-md-18, .col-md-19, .col-md-20,
.col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-13, .col-lg-14, .col-lg-15, .col-lg-16, .col-lg-17, .col-lg-18, .col-lg-19, .col-lg-20,
.col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-13, .col-xl-14, .col-xl-15, .col-xl-16, .col-xl-17, .col-xl-18, .col-xl-19, .col-xl-20 {
	@extend .col;
}

.col-1 {
	width: $col-width-1 !important;
}

.col-2 {
	width: $col-width-2 !important;
}

.col-3 {
	width: $col-width-3 !important;
}

.col-4 {
	width: $col-width-4 !important;
}

.col-5 {
	width: $col-width-5 !important;
}

.col-6 {
	width: $col-width-6 !important;
}

.col-7 {
	width: $col-width-7 !important;
}

.col-8 {
	width: $col-width-8 !important;
}

.col-9 {
	width: $col-width-9 !important;
}

.col-10 {
	width: $col-width-10 !important;
}

.col-11 {
	width: $col-width-11 !important;
}

.col-12 {
	width: $col-width-12 !important;
}

.col-13 {
	width: $col-width-13 !important;
}

.col-14 {
	width: $col-width-14 !important;
}

.col-15 {
	width: $col-width-15 !important;
}

.col-16 {
	width: $col-width-16 !important;
}

.col-17 {
	width: $col-width-17 !important;
}

.col-18 {
	width: $col-width-18 !important;
}

.col-19 {
	width: $col-width-19 !important;
}

.col-20 {
	width: $col-width-20 !important;
}

.col-xs-1 {
	width: $col-width-1 !important;
}

.col-xs-2 {
	width: $col-width-2 !important;
}

.col-xs-3 {
	width: $col-width-3 !important;
}

.col-xs-4 {
	width: $col-width-4 !important;
}

.col-xs-5 {
	width: $col-width-5 !important;
}

.col-xs-6 {
	width: $col-width-6 !important;
}

.col-xs-7 {
	width: $col-width-7 !important;
}

.col-xs-8 {
	width: $col-width-8 !important;
}

.col-xs-9 {
	width: $col-width-9 !important;
}

.col-xs-10 {
	width: $col-width-10 !important;
}

.col-xs-11 {
	width: $col-width-11 !important;
}

.col-xs-12 {
	width: $col-width-12 !important;
}

.col-xs-13 {
	width: $col-width-13 !important;
}

.col-xs-14 {
	width: $col-width-14 !important;
}

.col-xs-15 {
	width: $col-width-15 !important;
}

.col-xs-16 {
	width: $col-width-16 !important;
}

.col-xs-17 {
	width: $col-width-17 !important;
}

.col-xs-18 {
	width: $col-width-18 !important;
}

.col-xs-19 {
	width: $col-width-19 !important;
}

.col-xs-20 {
	width: $col-width-20 !important;
}

@media (max-width: ($min-width-sm - 1)) {

	.hidden-xs {
		display: none !important;
	}
	
	.visible-xs, .visible-xs-block {
		display: block !important;
	}
	
	.visible-xs-inline {
		display: inline !important;	
	}
	
	.visible-xs-inline-block {
		display: inline-block !important;	
	}


	.fright-xs, .f-right-xs, .float-right-xs {
		float: right;
	}

	.fleft-xs, .f-left-xs, .float-left-xs {
		float: left;
	}
	
}

@media (min-width: $min-width-sm) {
	
	.container {
		width: $container-width-sm;
	}
	
	.col-sm-1 {
		width: $col-width-1 !important;
	}

	.col-sm-2 {
		width: $col-width-2 !important;
	}

	.col-sm-3 {
		width: $col-width-3 !important;
	}

	.col-sm-4 {
		width: $col-width-4 !important;
	}

	.col-sm-5 {
		width: $col-width-5 !important;
	}

	.col-sm-6 {
		width: $col-width-6 !important;
	}

	.col-sm-7 {
		width: $col-width-7 !important;
	}

	.col-sm-8 {
		width: $col-width-8 !important;
	}

	.col-sm-9 {
		width: $col-width-9 !important;
	}

	.col-sm-10 {
		width: $col-width-10 !important;
	}

	.col-sm-11 {
		width: $col-width-11 !important;
	}

	.col-sm-12 {
		width: $col-width-12 !important;
	}

	.col-sm-13 {
		width: $col-width-13 !important;
	}

	.col-sm-14 {
		width: $col-width-14 !important;
	}

	.col-sm-15 {
		width: $col-width-15 !important;
	}

	.col-sm-16 {
		width: $col-width-16 !important;
	}

	.col-sm-17 {
		width: $col-width-17 !important;
	}

	.col-sm-18 {
		width: $col-width-18 !important;
	}

	.col-sm-19 {
		width: $col-width-19 !important;
	}

	.col-sm-20 {
		width: $col-width-20 !important;
	}
	
	body {

		#debug-toggle-wrapper {

			#debug-toggle {

				&:after {
					content: "sm";
				}

			}

		}

	}

}

@media (min-width: $min-width-sm) and (max-width: ($min-width-md - 1)) {

	.hidden-sm {
		display: none !important;
	}
	
	.visible-sm, .visible-sm-block {
		display: block !important;
	}
	
	.visible-sm-inline {
		display: inline !important;	
	}
	
	.visible-sm-inline-block {
		display: inline-block !important;	
	}

	.fright-sm, .f-right-sm, .float-right-sm {
		float: right;
	}

	.fleft-sm, .f-left-sm, .float-left-sm {
		float: left;
	}
  
}

@media (min-width: $min-width-md) {
	
	.container {
		width: $container-width-md;
	}

	.col-md-1 {
		width: $col-width-1 !important;
	}

	.col-md-2 {
		width: $col-width-2 !important;
	}

	.col-md-3 {
		width: $col-width-3 !important;
	}

	.col-md-4 {
		width: $col-width-4 !important;
	}

	.col-md-5 {
		width: $col-width-5 !important;
	}

	.col-md-6 {
		width: $col-width-6 !important;
	}

	.col-md-7 {
		width: $col-width-7 !important;
	}

	.col-md-8 {
		width: $col-width-8 !important;
	}

	.col-md-9 {
		width: $col-width-9 !important;
	}

	.col-md-10 {
		width: $col-width-10 !important;
	}

	.col-md-11 {
		width: $col-width-11 !important;
	}

	.col-md-12 {
		width: $col-width-12 !important;
	}

	.col-md-13 {
		width: $col-width-13 !important;
	}

	.col-md-14 {
		width: $col-width-14 !important;
	}

	.col-md-15 {
		width: $col-width-15 !important;
	}

	.col-md-16 {
		width: $col-width-16 !important;
	}

	.col-md-17 {
		width: $col-width-17 !important;
	}

	.col-md-18 {
		width: $col-width-18 !important;
	}

	.col-md-19 {
		width: $col-width-19 !important;
	}

	.col-md-20 {
		width: $col-width-20 !important;
	}

	body {

		#debug-toggle-wrapper {

			#debug-toggle {

				&:after {
					content: "md";
				}

			}

		}

	}
	
}

@media (min-width: $min-width-md) and (max-width: ($min-width-lg - 1)) {

	.hidden-md {
		display: none !important;
	}
	
	.visible-md, .visible-md-block {
		display: block !important;
	}
	
	.visible-md-inline {
		display: inline !important;	
	}
	
	.visible-md-inline-block {
		display: inline-block !important;	
	}

	.fright-md, .f-right-md, .float-right-md {
		float: right;
	}

	.fleft-md, .f-left-md, .float-left-md {
		float: left;
	}

}

@media (min-width: $min-width-lg) {
	
	.container {
		width: $container-width-lg;
	}
	
	.col-lg-1 {
		width: $col-width-1 !important;
	}

	.col-lg-2 {
		width: $col-width-2 !important;
	}

	.col-lg-3 {
		width: $col-width-3 !important;
	}

	.col-lg-4 {
		width: $col-width-4 !important;
	}

	.col-lg-5 {
		width: $col-width-5 !important;
	}

	.col-lg-6 {
		width: $col-width-6 !important;
	}

	.col-lg-7 {
		width: $col-width-7 !important;
	}

	.col-lg-8 {
		width: $col-width-8 !important;
	}

	.col-lg-9 {
		width: $col-width-9 !important;
	}

	.col-lg-10 {
		width: $col-width-10 !important;
	}

	.col-lg-11 {
		width: $col-width-11 !important;
	}

	.col-lg-12 {
		width: $col-width-12 !important;
	}

	.col-lg-13 {
		width: $col-width-13 !important;
	}

	.col-lg-14 {
		width: $col-width-14 !important;
	}

	.col-lg-15 {
		width: $col-width-15 !important;
	}

	.col-lg-16 {
		width: $col-width-16 !important;
	}

	.col-lg-17 {
		width: $col-width-17 !important;
	}

	.col-lg-18 {
		width: $col-width-18 !important;
	}

	.col-lg-19 {
		width: $col-width-19 !important;
	}

	.col-lg-20 {
		width: $col-width-20 !important;
	}

	body {

		#debug-toggle-wrapper {

			#debug-toggle {

				&:after {
					content: "lg";
				}

			}

		}

	}
	
}

@media (min-width: $min-width-lg) and (max-width: ($min-width-xl - 1)) {

	.hidden-lg {
		display: none !important;
	}
	
	.visible-lg, .visible-lg-block {
		display: block !important;
	}
	
	.visible-lg-inline {
		display: inline !important;	
	}
	
	.visible-lg-inline-block {
		display: inline-block !important;	
	}

	.fright-lg, .f-right-lg, .float-right-lg {
		float: right;
	}

	.fleft-lg, .f-left-lg, .float-left-lg {
		float: left;
	}
	
}

@media (min-width: $min-width-xl) {
	
	.container {
		width: $container-width-xl;
	}
	
	.col-xl-1 {
		width: $col-width-1 !important;
	}

	.col-xl-2 {
		width: $col-width-2 !important;
	}

	.col-xl-3 {
		width: $col-width-3 !important;
	}

	.col-xl-4 {
		width: $col-width-4 !important;
	}

	.col-xl-5 {
		width: $col-width-5 !important;
	}

	.col-xl-6 {
		width: $col-width-6 !important;
	}

	.col-xl-7 {
		width: $col-width-7 !important;
	}

	.col-xl-8 {
		width: $col-width-8 !important;
	}

	.col-xl-9 {
		width: $col-width-9 !important;
	}

	.col-xl-10 {
		width: $col-width-10 !important;
	}

	.col-xl-11 {
		width: $col-width-11 !important;
	}

	.col-xl-12 {
		width: $col-width-12 !important;
	}

	.col-xl-13 {
		width: $col-width-13 !important;
	}

	.col-xl-14 {
		width: $col-width-14 !important;
	}

	.col-xl-15 {
		width: $col-width-15 !important;
	}

	.col-xl-16 {
		width: $col-width-16 !important;
	}

	.col-xl-17 {
		width: $col-width-17 !important;
	}

	.col-xl-18 {
		width: $col-width-18 !important;
	}

	.col-xl-19 {
		width: $col-width-19 !important;
	}

	.col-xl-20 {
		width: $col-width-20 !important;
	}

	body {

		#debug-toggle-wrapper {

			#debug-toggle {

				&:after {
					content: "xl";
				}

			}

		}

	}
	
	.hidden-xl {
		display: none !important;
	}
	
	.visible-xl, .visible-xl-block {
		display: block !important;
	}
	
	.visible-xl-inline {
		display: inline !important;	
	}
	
	.visible-xl-inline-block {
		display: inline-block !important;	
	}

	.fright-xl, .f-right-xl, .float-right-xl {
		float: right;
	}

	.fleft-xl, .f-left-xl, .float-left-xl {
		float: left;
	}
	
}