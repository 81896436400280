/** 
	IMPORTANT: These styles ONLY apply for screen widths
	BELOW the first break-point 
	
	Media query is not needed inside this file
**/

.page-giftcards-overview {
	.block-system {
		form {
			.form-actions {
				padding-bottom: 54px;
			}
		}
	}
}

#navbar {
	&.fixed {
		.container {
			>.row {
				#main-menu {
					.nav-toggle {
						top: 26px;
					}
					.region-menu {
						#block-giftcard-form-giftcard-small-cart {
							top: 28px;
						}
					}
				}
				.logo {
					height: 75px;
					padding-top: 20px;
				}
			}
		}
	}
}

footer {
	padding-bottom: 15px;
	padding-top: 15px;
	.region-pre-footer1 {
		.block-nodeblock {
			&.block-newsletter {
				.content {
					input.form-submit {
						font-size: 15px;
					}
				}
			}
		}
	}
	.pre_footer1 {
		.block {
			margin-bottom: 20px;
		}
		.block.block-contact .field-item p:nth-of-type(2) {
			margin-bottom: 5px;
		}
		.block.block-klantenservice {
			.field-item {
				p:nth-of-type(2) {
					font-size: 23px;
				}
			}
		}
		.block.block-contact {
			.field-item {
				p:nth-child(2) {
					//margin-bottom: 15px;
				}
			}
		}
	}
}

.front {
	& footer {
		margin-top: -30px;
	}
	.region-content {
		.block.block-system {
			.field-name-body {
				h2,
				h3 {
					font-size: 19px;
					margin-bottom: 10px;
				}
			}
		}
	}
	.region-highlited {
		.block.block-views {
			.view-header {
				p {
					font-size: 22px;
					margin-top: -20px;
				}
			}
		}
		.node {
			.field-item {
				div:nth-child(3) {
					.button {
						font-size: 16px;
					}
				}
			}
		}
	}
}

.block.block-front-header h1 {
	font-size: 23px;
}

.not-front .node.node-page .field-name-body h2,
.page-giftcards .block-giftcard-ndb .field-item h1,
.page-giftcards .block-giftcard-ndb .field-item h2 {
	font-size: 23px;
	margin-top: -10px;
}

.not-front .region.region-content .block {
	margin-bottom: 20px;
}

.page-giftcards {
	&.page-giftcards-extra {
		#edit-right>h2 {
			font-size: 19px;
			margin-bottom: 25px;
			margin-top: 0px;
		}
		.view-wrappers.view-id-wrappers {
			.view-content {
				display: flex;
				flex-wrap: wrap;
				width: 92vw;
				position: relative;
				right: calc(46vw - 50%);
				justify-content: space-around;
				.views-row {
					width: 48%;
					padding: 10px;
					>.node {
						width: 100%!important;
					}
					.field-name-title {
						p.last-child {
							text-align: center;
						}
					}
				}
			}
		}
		.view-wines.view-id-wines {
			.field-name-title {
				p.last-child {
					text-align: left!important;
				}
			}
		}
	}
	&.page-giftcards-overview {
		.block-system {
			form {
				.form-actions {
					padding-bottom: 10px;
				}
			}
			#edit-reference {
				h4 {
					margin-bottom: 15px;
				}
				a {
					margin-top: 15px;
				}
			}
			#edit-payment {
				.header {
					margin-bottom: 15px;
				}
			}
			.delivery-date-title {
				font-size: 23px;
				font-weight: bold;
				margin-top: 20px;
				margin-bottom: 15px;
			}
		}
	}
	&.page-giftcards-extra,
	&.page-giftcards-overview {
		.block-system {
			.form-actions {
				padding-top: 40px;
			}
			form>div>a {
				bottom: 45px;
			}
		}
	}
	.block-system {
		form {
			.giftcard-txt1 {
				margin-bottom: 10px;
				h2 {
					font-size: 23px;
				}
			}
		}
		.paymanet-summery {
			margin-top: 18px;
			padding: 15px 30px;
			h3 {
				font-size: 23px;
			}
		}
		#edit-cart {
			.giftcard-cart {
				h3 {
					font-size: 23px;
				}
			}
		}
	}
}

.block.block-ndb-big {
	.content {
		padding: 15px 25px;
	}
}

.block.block-contact-contacts {
	.content {
		padding: 15px 25px;
		.field-item {
			h2,
			>div:nth-of-type(1) {
				font-size: 23px;
			}
			p {
				margin-bottom: 15px;
			}
		}
	}
}

.block.block-ndb-big .right li {
	font-size: 14px;
	margin-bottom: 7px;
}

.node-type-webform {
	.block.block-ndb-big .btn .button {
		font-size: 16px;
	}
}

.page-giftcards-extra {
	#edit-right {
		.view.view-wrappers {
			.node {
				.field-name-amount-form {
					form {
						div {
							>.form-wrapper {
								text-align: center;
								.form-wrapper {
									margin-bottom: 6px;
								}
								input[type='submit'] {
									font-size: 15px;
									margin-left: 0px;
									padding: 2px 14px;
								}
							}
						}
					}
				}
				.field-name-uc-product-image {
					padding-bottom: 20px;
					margin-bottom: 20px;
				}
				[id^=edit-ammount-wrapper] {
					padding: 0 6px;
				}
			}
		}
	}
}

#navbar #main-menu .region-menu #block-giftcard-form-giftcard-small-cart a:after {
	left: 0px;
	transform: scale(0.63);
	transform-origin: 0 0;
}
