/**
	IMPORTANT: These styles apply for screen widths
	ABOVE and INCLUDING the fourth break-point
	They are NOT LIMITED to a maximum width

	Media query is not needed inside this file
**/

#navbar {

	#main-menu {

		.region-menu {
			padding-top: 36px;

			#block-giftcard-form-giftcard-small-cart {
				right: 0;
				top: 37px;
			}

			.block-menu-block {

				.content {
					margin-right: 160px;
					margin-top: -3px;
				}

				.menu {
					margin-left: 0;
					
					li {
						margin-right: 6px;
						margin-top: 10px;

						a {
							font-size: 18px;
							padding: 8px 16px;
						}
					}
				}
			}

			.block-giftcard-header {
				.field-item {
					padding: 16px 0;

					strong {
						display: inline;
					}
				}
			}
		}
	}
}

.highlited-wrapper {
	.front & {
		margin-top: -35px;
	}
}

.node {
	.front .region-highlited & {
		.field-item {
		    // padding-bottom: 16px;

			div {
				&:nth-child(1) {
					&:before,
					&:after {
						content: "";
						display: block;
						width: 100px;
						height: 225px;
						top: 0;
						width: 100px;
						position: absolute;
						z-index: 1;
					}

					&:before {
						left: 0;
						background: linear-gradient(to left, transparent, $white 60px );
					}

					&:after {
						right: 0;
						background: linear-gradient(to right, transparent, $white 60px );
					}
				}

				&:nth-child(5),
				&:nth-child(6) {
					width: $container-width-xl / 2;
					position: absolute;
					top: 260px;
					z-index: -1;

					p:not(:last-child) {
						margin-bottom: 10px;
					}
				}

				&:nth-child(5) {
					right: 50%;
					padding-right: 340px;
				}

				&:nth-child(6) {
					left: 50%;
					padding-left: 350px;
				}
			}
		}
	}

	&.node-page {
		&.node-page-full {
			> .group-left {
				width: 770px;
			}

			> .group-right {
				width: 360px;
			}

			.block-ndb-small {
			    padding: 28px;
			}
		}
	}
}

footer {
	padding-bottom: 50px;
	padding-top: 58px;

	&:before {
		background-size: 100% 14px;
	}

	.pre_footer1 {

		.container {
			&:before {
				right: 0;
				top: -6px;
			}
		}
	}

	.pre_footer2 {
		min-height: 266px;
	}

	.pre_footer1,
	.pre_footer2 {
		position: relative;
		z-index: 2;

		.block {
			width: 23% !important;
			margin-left: 3%;

			&:first-child {
				margin-left: 0;
			}
		}
	}

	.footer {
		position: absolute;
		bottom: 50px;
		right: 0;
		width: 100%;
		text-align: right;
		z-index: 0;

		.block {
			&.block-footer-payments {
				float: none;
				margin-bottom: 28px;
			}
		}
	}
}

.block {
	&.block-views {
		.front .region-highlited & {
			&:before,
			&:after {
				background-size: 100% 14px;
			}
			
			.content {
				width: $container-width-xl;
			}

			.view-content {
				.views-row {
					width: 23%;
					margin-bottom: 25px;
					margin-left: 2%!important;			
				}

				.field-name-node-link {
					a {
						margin-top: 10px;
					}
				}

				.field-name-title {
					margin-bottom: 12px;
				}
			}
		}
	}

	&.block-overons-side {
		.field-name-body {
			@include clearfix;
			.left,
			.right {
				width: auto;
				float: none;
			}

			.right {
				margin-left: 0%;
			}
		}
	}

	body.sidebar-page .region-content & {
		width: $col-width-7 + $col-width-1 * 0.7;

		&.block-sidebar {
			width: $col-width-3 + $col-width-1 * 0.6;
			position: absolute;
			right: 0;
			top: 40px;
		}
	}

	&.block-system {
		.not-front.page-search-results & {
			.views-row {
				width: 31%;

				&:nth-child(2n) {
					margin-left: 0%;
				}

				&:nth-child(2n+1) {
					clear: none;
				}

				&:nth-child(3n+1) {
					clear: left;
				}

				&:nth-child(3n+2),
				&:nth-child(3n) {
					margin-left: 3.5%;
				}
			}
		}

		.node-type-restaurant & {
			.group-restaurant-info {
				width: 35%;
				min-height: 248px;
			    padding: 23px 25px;
			}

			.field-name-field-head-image-detailed {
				width: 56%;
				margin-left: 9%;
			}
		}

		.contact-page & {
			form {
				margin-top: 31px;

				> div {
					width: 516px;
					margin-left: auto;
				}

				.form-item {
					position: relative;
				    margin: 5px 0px;

					&:nth-child(n) {
						width: 100%;
						margin-left: 0;
					}

					&:nth-child(3) {
						width: 36%;
					}

					&:nth-child(4),
					&:nth-child(5) {
						width: 30%;
					}

					&:nth-child(1),
					&:nth-child(2),
					&:nth-child(6),
					&:nth-child(7),
					&:nth-child(8),
					&:nth-child(9) {
						width: 49%;
					}

					&:nth-child(2),
					&:nth-child(4),
					&:nth-child(5),
					&:nth-child(7),
					&:nth-child(9) {
						margin-left: 2%;
					}

					.field-prefix {
						display: block;
						position: absolute;
						left: -249px;
						top: 10px;
					}

					&.webform-component-textarea {
						> label {
							display: block;
							position: absolute;
							left: -249px;
							top: 7px;
							color: $black;
							font-weight: bold;

							span {
								display: none;
							}
						}
					}
				}
			}
		}

		.zakelijk-page & {
			.delivery-date-zakelijk {
				display: inline-block;
			    margin-left: 20px;
			}
		}
	}

	&.block-giftcard-breadcrumbs {
		.content {
			div {
				.arrow {
					margin: 0 20px;
				}
			}
		}
	}

	&.block-load-more {
		.field-item {
			a {
				& + span {
					position: relative;
					top: -20px;
					width: 100%;
				}
			}
		}
	}

	&.block-nxte-map-search#block-nxte-map-search-nxte-map-search-search-form {
		form {
			> div {
				@include clearfix;

				> p,
				#edit-container {
					float: left;
				}

				> p {
					width: 34%;
					margin-top: 8px;
				}

				#edit-container {
					width: 64%;
					margin-left: 2%;
				}
			}
		}
	}

	&.block-ndb-big {
		.field-item {
			position: relative;
			@include clearfix;
		}

		.content {
			padding: 36px 40px;
		}

		.left {
			float: left;
			width: 280px;
			margin-bottom: 0;
		}

		.right,
		.btn {
			width: 500px;
			margin-left: 36px;
		}

		.btn {
			float: left;
		}

		.right {

			ul {
				@include clearfix;

				li {
					font-size: 18px;
					float: left;
					width: 45%;
					margin-bottom: 15px;

					&:nth-child(2n) {
						margin-left: 10%;
					}
				}
			}
		}

		.betaal {
			width: 206px;
		    padding: 10px 20px 18px;

			div {
				&:nth-child(1) {
					span {
						display: block;
						padding-bottom: 12px;
					}

					img {
						margin-left: 12px;
						&:nth-child(2) { margin-left: 0; }
					}
				}

				&:nth-child(2) {
				    padding: 20px 0 5px;
				}
			}
		}
	}
	
	&.block-zakelijk-popup {
		form.webform-client-form {
			.webform-component--vul-onderstande {
				h2 {
					max-width: 450px;
				}
			}

			.form-item {
				float: left;
				width: 100%;

				&:nth-child(2),
				&:nth-child(7) {
					width: 32%;
					margin-right: 2%;
				}

				&:nth-child(3),
				&:nth-child(8) {
					width: 66%;
				}
				&:nth-child(4) {
					width: 52%;
				}

				&:nth-child(5),
				&:nth-child(6) {
					width: 22%;
					margin-left: 2%;
				}
			}

			.form-actions {
				clear: left;
			}
		}
	}

	&.block-zakelijk-slider {
		width: 30% !important;

		&.block-zakelijk-slider-1 {
			margin-bottom: 0 !important;
		}

		&.block-zakelijk-slider-2,
		&.block-zakelijk-slider-3 {
			margin-left: 5%;
		}

		.field-name-field-slider {
			margin: 30px 0;
		}
	}
}

.region {
	&.region-content {
		.node-type-restaurant & {
			.ds-2col {
				.group-left {
					width: $col-width-7 + $col-width-1 * 0.7;
				}

				.group-right {
					margin-top: 0;
					position: absolute;
					right: 0;
					top: 72px;
					width: $col-width-3 + $col-width-1 * 0.6;
				}
			}

			.block-system ~ .block {
				width: $col-width-7 + $col-width-1 * 0.7;
			}
		}

		.contact-page & {
			.block-system {
				width: $col-width-7 + $col-width-1 * 0.65;
				float: left;
			}

			.block-contact-map,
			.block-contact-contacts {
				width: $col-width-3 + $col-width-1 * 0.65;
				float: right;
				margin-left: 0;
			}

			.block-contact-map {
				iframe {
					height: 176px;
				}
			}
		}

		.saldocheck-page & {
			.block-system, 
			.block-saldocheck-paycard {
				width: 48%;
			}

			.block-system {
				float: left;
			}

			.block-saldocheck-paycard {
				float: right;
			}
		}

		.zakelijk-page & {
			.block-system,
			.block-phone-guy {
				width: 770px;
			}

			.block-phone-guy {
			    margin-bottom: 80px;

				.field-item {
					max-width: 480px;

					p {
						&:last-child {
							right: 60px;
						}
					}
				}
			}

			.block-ndb-small {
				width: 360px;

				.content {
					padding: 28px;
				}
			}
		}
	}
}

.page-restaurants {
	.region-content {
		.views-row {
			width: 31%;

			&:nth-child(2n) {
				margin-left: 0;
			}

			&:nth-child(3n),
			&:nth-child(3n+2) {
				margin-left: 3.5%;
			}

			&:nth-child(2n+1) {
				clear: none;
			}

			&:nth-child(3n+1) {
				clear: left;
			}
		}
	}

	#block-nxte-location-view-restaurant-search {
		.form-wrapper {
			width: 31%;
		}

		p {
			float: left;
			width: 34%;
			line-height: 60px;
		    margin-bottom: 0;
		}

		p,
		.form-wrapper#edit-left {
			margin-right: 2%;
		}
	}
}

$giftcard-content-width-xl: 750px;
$giftcard-sidebar-width-xl: 380px;
.page-giftcards {
	.block-giftcard-ndb {
		width: $giftcard-content-width-xl;
	}

	.messages {
		width: $giftcard-content-width-xl;
	}

	.block-system {
		form {
			.form-actions {
				width: $giftcard-content-width-xl;
			}
		}

		#edit-delivery {
			.delivery,
			.delivery-date {
				width: auto;
			}

			.delivery {
				border-top-left-radius: 5px;
				border-bottom-left-radius: 5px;
				padding-right: 0;
			}

			.delivery-date {
				min-width: 237px;
				border-top-right-radius: 5px;
				border-bottom-right-radius: 5px;
			}
		}

		#edit-cart {
			padding: 25px 28px 25px 20px;

			.giftcard-cart {
				.item-list {
					li {
						@include clearfix;
						font-size: 15px;
					}

					.card-qty {
						display: inline-block;
					    margin-left: 5px;
					    vertical-align: top;
					}

					.price {
						position: static;
						min-width: auto;

						.uc-price {
							min-width: auto;
						}
					}

					.title {
						// max-width: 175px;
						display: inline-block;
					}
				}
			}

			.giftcard-pane-totals {
				span {
					min-width: auto;
				}
			}
		}

		.giftcard-pane-right{
			width: $giftcard-content-width-xl;

			.giftcard-pane-right-middle {
				border: 0;
				padding: 0;
				
				.form-item-giftcard-sell-price {
					margin-left: 110px;
				}
				div:last-child {
					display: inline-block;
					margin-left: 74px;
					max-width: 174px;
				}
			}

			.giftcard-pane-right-right {
				#edit-top {
					.extra-products {
						.giftcard-form-extra-product {
							.giftcard-form-extra-product-right {
								width: 263px;
							}
						}
					}
				}
			}
		}

		.giftcard-pane-cart-sidebar {
			width: $giftcard-sidebar-width-xl;
		}
	}
}

.page-giftcards-extra {
	#edit-right {
		width: $giftcard-content-width-xl;

		.view {
			.views-row {
				width: 33.33%;

				&:nth-child(2n+1) {
					clear: none;
				}

				&:nth-child(3n+1) {
					clear: left;
				}
			}
		}

		.extra-message{
			width: 280px;
			float: left;
		}

		.form-item-s-remark {
			float: right;
			width: 465px;
		}
	}
}

.page-giftcards-shipping {
	.block-system {
		.giftcard-pane-right {
			#edit-billing,
			#edit-shipping {
				.personal-pane-left {
					> .form-type-textfield,
					> .form-wrapper {
						width: 600px;
					}
				}
			}
		}
	}
}

.page-giftcards-overview {
	.block-system {
		#edit-cart {
			padding: 0 20px 0 0;
		}
	}
}