/**
	IMPORTANT: These styles ONLY apply for screen widths
	BETWEEN the second and third break-point

	Media query is not needed inside this file
**/

.block {
	&.block-ndb-small {
		.left {
			width: 310px;
		}

		.right {
			width: 290px;
			margin-left: 20px;

			.button {
				width: 500px;
				margin-left: -260px;
			}
		}
	}
}