/**
	IMPORTANT: These styles apply for screen widths
	ABOVE and INCLUDING the first break-point
	They are NOT LIMITED to a maximum width

	Media query is not needed inside this file
**/

#navbar {

	.logo {

		.front & {
			padding-top: 30px;
			padding-bottom: 0;
		}
		

		a {
			width: 270px;
		}
	}

	#main-menu {
		.nav-toggle-wrapper {
			.nav-toggle {
				width: 31px;
				padding-top: 33px;
				font-size: 10px;
				top: 30px;

				.front & {
					top: 40px;
				}
			}
		}

		.region-menu {
			.block-menu-block {
				display: none;
			}

			#block-giftcard-form-giftcard-small-cart {
				top: 30px;

				.front & {
					top: 40px;
				}

				a {
					width: 54px;
					height: 46px;

					.small-cart-amount {
						right: 3px;
						bottom: 19px;
						color: $color2;
					}
				}
			}
		}
	}
}

.block {
	&.block-views {
		.front .region-highlited & {
			.content {
				width: $container-width-sm;
			}

			.view {
				width: 346px;
				margin: 0 auto;
			}
		}
	}

	&.block-ndb-small {
		.button {
			padding: 5px 40px;
		}
	}

	&.block-ndb-big {
		.betaal {
			div {
				&:nth-child(1) {
					span {
						display: inline-block;
					}

					img {
						&:nth-child(2) {
							margin-left: 5px;
						}
					}
				}
			}
		}
	}

	&.block-system {
		.saldocheck-page & {
			iframe {
				height: 126px;
			}
		}

		.zakelijk-page & {
			.delivery-date-zakelijk {
				.gratis,
				.shipping-date {
					display: inline-block;
				}

				.shipping-date {
					margin-left: 5px;
				}
			}
		}
	}

	&.block-phone-guy {
		position: relative;
		padding-bottom: 10px;

		.contextual-links-region {
			position: static;
		}

		.field-item {
			max-width: 260px;

			p:last-child {
				position: absolute;
				bottom: 0;
				right: 0;
			}
		}
	}
}

#modalContent {
	form {
		.form-item {
			float: left;
			width: 100%;

			&.webform-component-textfield,
			&.webform-component-email {
				width: 48%;

				&:nth-of-type(2n+1) {
					margin-left: 4%;
				}
			}
		}
	}
}

.page-giftcards {
	.block-system {
		#edit-cart {
			padding: 25px 35px;

			.giftcard-cart {
				.item-list {
					.card-qty {
						display: inline-block;
						margin-left: 5px;
					}

					.price {
						position: static;
						float: right;
						padding-right: 10px;

						span {
							min-width: 58px;
							display: inline-block;
						}
					}

					a {
						right: -15px;
						top: 6px;
					}
				}
			}

			.giftcard-pane-totals {
				span {
					padding-right: 10px;
					min-width: 68px;
				}
			}
		}


		.giftcard-pane-cart-sidebar {
			.block-giftcard-review {
				.fieldset-right {
					margin-top: 20px;
				}
				
				.score {
					height: 108px;
					position: absolute;
					top: 60px;
					left: 220px;
				}
			}
		}

		.giftcard-pane-right {
			.giftcard-pane-right-middle {
				.form-item-giftcard-amount {
					margin-left: 90px;

					&:before {
						left: -90px;
					}
				}

				.form-item-giftcard-sell-price {
					> label {
						max-width: none;
						line-height: 38px;
					    margin-right: 20px;
					}
				}
			}

			.giftcard-pane-right-right {
				#edit-top {
					.form-item-giftcard-form-extra-product {
						min-height: 64px;

						.form-item-giftcard-form-extra-product-1 {
							.icheckbox {
								margin-top: 8px;
							}

							&:before {
								top: 0;
							}

							label {
								line-height: 29px;
							}
						}
					}

					.extra-products {
						.giftcard-form-extra-product {
							width: auto;

							.giftcard-form-extra-product-right {
								width: 300px;

								.field-name-body {
									min-height: 44px;
								}
							}
						}
					}
				}

				#edit-bottom {
					.form-item-giftcard-wrapper {
						#edit-giftcard-wrapper {
							min-height: 62px;

							&:before {
								top: 4px;
							}

							.icheckbox {
								margin-top: 12px;
							}

							label {
								padding-top: 20px;
							}
						}
					}
				}
			}
		}
	}
}

.page-giftcards-payment {
	.block-system {
		.giftcard-pane-right {
			.form-item-payment-method {
				.form-item {
					label {
						max-width: none;
						background-position-y: center !important
					}

					.iradio {
						top: 4px;
					}
				}
			}
		}
	}
}

.page-giftcards-overview {
	.block-system {
		#edit-cart {
			padding: 0;
		}
	}
}