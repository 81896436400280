/**
	IMPORTANT: These styles apply for screen widths
	ABOVE and INCLUDING the second break-point
	They are NOT LIMITED to a maximum width

	Media query is not needed inside this file
**/

#navbar {
	
	#main-menu {

		.region-menu {

			#block-giftcard-form-giftcard-small-cart {
				a {
					width: 140px;
					background-position: right center;
					background-size: auto 100%;

					.small-cart-label {
						display: block;
						font-size: 13px;
						font-weight: $normal;
						color: $color3;
						padding-top: 15px;
					}
				}
			}

			.block-tuisvinkel {
				display: block;
				position: absolute;
				right: 0;
				top: 25px;

				.content {
					width: 32px;
					height: 32px;
					background: url("../images/thuiswinkel.png") no-repeat center center / 100%;
					overflow: hidden;
				}

				.field-item {
					text-indent: 50px;
				}
			}
		}
	}
}

.highlited-wrapper {
	.not-front & {
		position: relative;
		min-height: 98px;

		h1,
		h2 {
			text-align: left;
		}
	}
}

.node {
	.region-highlited & {
		.field-item {
			position: relative;
			@include clearfix;
			text-align: center;

			div {
				&:nth-child(2) {
					img {
						max-width: none;
					}
				}

				&:nth-child(1) {
					display: block;
					position: absolute;
					top: 35px;
				    z-index: -1;
				    left: 50%;
				    transform: translateX(-50%);

				    img {
				    	max-width: none;
				    }
				}

				&:nth-child(4) {
					span {
						display: inline;
						font-size: 16px;
					}
				}

				&:nth-child(2),
				&:nth-child(3),
				&:nth-child(4) {
					max-width: none;
					width: 444px;
				}

				&:nth-child(5),
				&:nth-child(6) {
					margin-top: 25px;
					font-size: 20px;
					max-width: none;
					width: ($container-width-md / 2) - 2;
					display: inline-block;
					padding-left: 0;
					padding-right: 0;

					p {
						background-position: left 4px;
						text-align: left;
					}
				}
			}
		}
	}

	&.node-restaurant {
		&.node-teaser {
			.field-name-title {
				h2 {
					a {
						min-height: 52px;
						display: inline-block;
						line-height: 1;
					}
				}
			}

			.field-name-field-image-main {
				img {
					width: 100%;
				}
			}
		}
	}

	&.node-page {
		.not-front & {
			.field-name-body {
				h2 {
					font-size: 36px;
				}
			}
		}
	}
}

footer {
	padding-bottom: 40px;

	.pre_footer1 {

		.container {
			&:before {
				content: "";
				display: block;
				@include sprite($nxte-colette);
				position: absolute;
				top: 0;
				right: 0;
			}
		}

		.block {
			float: left;
			width: 100%;

			.content {
				max-width: 207px;
			}

			&:first-child {
				width: 31%;
			}

			&:nth-child(2) {
				width: 66%;
				margin-left: 3%;
			}
		}
	}

	.pre_footer2 {
		display: block;

		.block {
			float: left;
			width: 31%;

			&:nth-child(2),
			&:nth-child(3) {
				margin-left: 3%;
			}

			h2 {
			    margin-bottom: 12px;
			}

		    .field-item {
		    	p {
	    		    margin-bottom: 5px;
		    	}
		    }
		}
	}

	.footer {
		margin-top: 15px;

		.block {
			&.block-footer-payments {
				margin-bottom: 0;
				float: right;
			}
		}
	}
}

.block {
	&.block-front-header {
		h1 {
			font-size: 36px;
		}
	}

	&.block-views {
		.front .region-highlited & {
			.content {
				width: $container-width-md;
			}

			.view {
				padding: 0;
				width: auto;
			}

			.view-header {
				padding-top: 32px;
				padding-bottom: 36px;

				p {
					font-size: 35px;
				}
			}

			.view-content {
				padding-bottom: 50px;
				@include clearfix;

				.views-row {
					width: 48%;
					float: left;
					&:nth-child(2n) {
						margin-left: 4%;
					}					

					.node {
						.field-item {
							text-align: left;
						}
					}
				}
			}
		}
	}

	&.block-page-title,
	.not-front .highlited-wrapper & {
		.field-name-body {
			padding: 10px 0;
		}

		h1,
		h2 {
			line-height: 78px;
			font-size: 30px;
		}
	}

	&.block-system {
		.not-front.page-search-results & {
			.content {
				.content {
					@include clearfix;
				}
			}
			.views-row {
				float: left;
				width: 48%;

				&:nth-child(2n) {
					margin-left: 4%;
				}

				&:nth-child(2n+1) {
					clear: left;
				}
			}
		}

		.node-type-restaurant & {
			.field-name-title {
				h1 {
					padding-right: 190px;
					font-size: 36px;
				}
			}


			.field-name-field-image,
			.field-name-field-head-image-detailed,
			.group-restaurant-info {
				float: left;
			}

			.field-name-field-image {
				width: 100%;
			}

			.group-restaurant-info {
				width: 40%;
				padding: 17px 25px;
			}

			.field-name-field-head-image-detailed {
				width: 56%;
				margin-left: 4%;
			}

			.group-right {
				clear: left;
			}
		}

		.contact-page & {
			form {
				> div {
					@include clearfix;
				}

				.form-item {
					width: 48%;
					float: left;

					&:nth-child(2n) {
						margin-left: 4%;
					}

					&.webform-component--opmerking,
					&.webform-component--contactformulier {
						width: 100%;
						margin-left: 0;
					}
				}
			}
		}

		.saldocheck-page & {
			iframe {
				height: 136px;
			}
		}
		
		.zakelijk-page & {
			.delivery-date-zakelijk {
				display: inline-block;
				margin-left: 20px;
			}
		}

		.front .region-content & {
			.field-name-body {
				h2, h3 {
					font-size: 26px;
				}
			}
		}
	}

	&.block-saldocheck-paycard {
		.field-item {
			h2, h3 {
				padding-top: 0;
			}

			.left {
				float: left;
				width: 260px;
			}

			.right {
				float: right;
			}

			p {
				clear: both;
				padding-top: 20px;
			}
		}
	}

	&.block-phone-guy {
		.field-item {
			max-width: 510px;
		}
	}

	&.block-overons-side {
		.field-name-body {
			@include clearfix;
			.left,
			.right {
				width: 48%;
				float: left;
			}

			.right {
				margin-left: 4%;
			}
		}
	}

	&.block-ndb-small {
		.content {
			@include clearfix;
			text-align: center;

			.node {
				display: inline-block;
			}
		}

		.field {
			text-align: left;
		}

		ul {
			li {
				margin-bottom: 10px;
			}
		}

		.right,
		.left {
			float: left;
		}

		.left {
			width: 280px;
		}

		.right {
			width: 400px;
			margin-left: 10px;
		}
	}

	&.block-ndb-big {
		.left {
			text-align: center;
		}

		.right,
		.betaal {
			width: 49%;
		}

		.right {
			float: left;
		}

		.betaal {
			float: right;
		}
	}

	&.block-nxte-map-search {
		&#block-nxte-map-search-nxte-map-search-search-form {
			margin-top: 48px;

			#edit-container {
				@include clearfix;
			}

			#edit-left,
			#edit-right {
				width: 48%;
				float: left;
			}

			#edit-right {
				margin-left: 4%;
			}
		}
	}

	&.block-zakelijk-slider {
		float: left;

		&.block-zakelijk-slider-2,
		&.block-zakelijk-slider-3 {
			width: 48%;
		}

		&.block-zakelijk-slider-3 {
			margin-left: 4%;
		}
	}
}

.region {
	&.region-content {
		.contact-page & {
			.block-contact-map,
			.block-contact-contacts {
				width: 48%;
				float: left;
			}

			.block-contact-map {
				iframe {
					height: 310px;
				}
			}

			.block-contact-contacts {
				margin-left: 4%;
			}

			.block-ndb-big {
				clear: left;
			}
		}

		.zakelijk-page & {
			.block-system {
				margin-bottom: 56px;
			}
		}
	}
}

.breadcrumbs {
    position: absolute;
    width: 100%;
    top: 0;
    padding-top: 40px;
    padding-bottom: 0;

    .content {
    	text-align: right;
    }

    ol {
    	display: inline-block;
    	width: auto;
    	margin-left: auto;
    }
}

.backlink {
	margin-top: 0px;

    .node-type-restaurant & {
		position: absolute;
		right: 0;
		top: 32px;
		z-index: 5;
	}
}

.page-restaurants {
	.region-content {
		.views-row {
			width: 48%;
			float: left;

			&:nth-child(2n) {
				margin-left: 4%;
			}

			&:nth-child(2n+1) {
				clear: left;
			}

			.node {
				.field-item {
					text-align: left;
				}
			}
		}
	}

	#block-nxte-location-view-restaurant-search {
		.content {
			@include clearfix;
		}

		.form-wrapper {
			float: left;
			width: 48%;

			&#edit-left {
				margin-right: 4%;

				input {
					top: 22px;
				}
			}
		}
	}
}

.page-giftcards {
	.block-giftcard-ndb {
		.field-item {
			h1, h2 {
				font-size: 36px;
			}
		}
	}

	.block-system {
		.giftcard-intro {
			.left {
				float: left;
				margin-right: 26px;
			}
		}

		form {
			.giftcard-txt1 {
				clear: both;

				h2 {
					font-size: 30px;
				}
			}
		}

		.giftcard-pane-right {
			.giftcard-pane-right-middle {
				.form-item-giftcard-amount {
					margin-left: 0;
					display: inline-block;

					&:before {
						left: auto;
						right: -90px
					}
				}

				.form-item-giftcard-sell-price {
					display: inline-block;
					margin-left: 100px;
				}
			}

			.giftcard-pane-right-right {
				#edit-top {
					.extra-products {
						.giftcard-form-extra-product {
							width: 50%;

							&:nth-child(2n+1) {
								clear: left;
								border-right: 1px solid $grey;
							}

							.giftcard-form-extra-product-right {
								width: 233px;
							}
						}
					}
				}
			}
		}
	}
}

.page-giftcards-extra {
	#edit-right {
		> h2 {
			font-size: 30px;
		}
		.view {
			@include clearfix;
			.views-row {
				width: 50%;
				float: left;

				&:nth-child(2n+1) {
					clear: left;
				}
			}
		}
	}
}