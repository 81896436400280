// COLORS
$black: #000000;
$white: #ffffff;
$green: #24c828;
$green-light: #17c51b;
$grey: #bababa;
$grey-light: #f2f2f2;
$blue-light: #ecf0f3;
$light-pink: #f9eede;
$pink: #e83659;
$golden: #caa978;
$golden-light: #f9eede;

$color1: $black;
$color2: $white;
$color3: #cb043d;
$color4: rgba($color3, .07);
$color5: rgba($color3, .5);
$color6: #c8c8c8;
$color7: #29235c;


$primary-color: $black;
$secondary-color: $white;

$third-color: #b89b60;
$fourth-color: #dfdfdf;
$fifth-color: #17c51b;
$sixth-color: #b79b61;
$grey-font: #b2b2b2;

// FONTS
$lato: 'Lato', sans-serif;

$font-family: $lato;
$font-color: $primary-color;
$font-size: 15px;
$line-height: 1.75em;

// FONTS WEIGHT
$thin: 100;
$extralight: 200;
$light: 300;
$normal: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;
$heavy: 900;

// GRID
$column-content-width: 70px;
$gutter-width: 30px;
$breakpoint-offset: 17px;

$columns-xl: 12;
$columns-lg: 9;
$columns-md: 7;
$columns-sm: 5;
$columns-xs: 3;

// DO NOT CHANGE ANYTHING BELOW HERE
// UNLESS YOU KNOW WHAT YOU ARE DOING

$column-width: ($column-content-width + $gutter-width);

$container-width-xs: ($columns-xs * $column-width) - $gutter-width;
$container-width-sm: ($columns-sm * $column-width) - $gutter-width;
$container-width-md: ($columns-md * $column-width) - $gutter-width;
$container-width-lg: ($columns-lg * $column-width) - $gutter-width;
$container-width-xl: ($columns-xl * $column-width) - $gutter-width;

$min-width-xs: ($columns-xs * $column-width) + $breakpoint-offset;
$min-width-sm: ($columns-sm * $column-width) + $breakpoint-offset;
$min-width-md: ($columns-md * $column-width) + $breakpoint-offset;
$min-width-lg: ($columns-lg * $column-width) + $breakpoint-offset;
$min-width-xl: ($columns-xl * $column-width) + $breakpoint-offset;

$col-width-1: $column-width;
$col-width-2: $column-width * 2;
$col-width-3: $column-width * 3;
$col-width-4: $column-width * 4;
$col-width-5: $column-width * 5;
$col-width-6: $column-width * 6;
$col-width-7: $column-width * 7;
$col-width-8: $column-width * 8;
$col-width-9: $column-width * 9;
$col-width-10: $column-width * 10;
$col-width-11: $column-width * 11;
$col-width-12: $column-width * 12;
$col-width-13: $column-width * 13;
$col-width-14: $column-width * 14;
$col-width-15: $column-width * 15;
$col-width-16: $column-width * 16;
$col-width-17: $column-width * 17;
$col-width-18: $column-width * 18;
$col-width-19: $column-width * 19;
$col-width-20: $column-width * 20;
