@import 'bourbon/bourbon.scss';
/* Documentation - http://bourbon.io/docs/ */
/*
Write three dotes, if u need more than one input argument.
Example: @mixin box-shadow($shadow...)

@include box-shadow(inset 0 1px 1px rgba($secondary-color, .075), 0 0 8px rgba($secondary-color, .6));

*/

@mixin border-radius($radius) {
  @include prefixer(border-radius, $radius, webkit moz o ms spec);
}

@mixin box-shadow($shadow...) {
  @include prefixer(box-shadow, $shadow, webkit moz o ms spec);
}

@mixin box-sizing($box-sizing) {
  @include prefixer(box-sizing, $box-sizing, webkit moz o ms spec);
}

@mixin background-size($size) {
  @include prefixer(background-size, $size, webkit moz o spec);
}