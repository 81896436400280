// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$nxte-achteraf-name: 'nxte-achteraf';
$nxte-achteraf-x: 1024px;
$nxte-achteraf-y: 232px;
$nxte-achteraf-offset-x: -1024px;
$nxte-achteraf-offset-y: -232px;
$nxte-achteraf-width: 31px;
$nxte-achteraf-height: 26px;
$nxte-achteraf-total-width: 1458px;
$nxte-achteraf-total-height: 878px;
$nxte-achteraf-image: '../images/nxte-sprite.png';
$nxte-achteraf: (1024px, 232px, -1024px, -232px, 31px, 26px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-achteraf', );
$nxte-arrow-down-name: 'nxte-arrow-down';
$nxte-arrow-down-x: 1223px;
$nxte-arrow-down-y: 155px;
$nxte-arrow-down-offset-x: -1223px;
$nxte-arrow-down-offset-y: -155px;
$nxte-arrow-down-width: 15px;
$nxte-arrow-down-height: 11px;
$nxte-arrow-down-total-width: 1458px;
$nxte-arrow-down-total-height: 878px;
$nxte-arrow-down-image: '../images/nxte-sprite.png';
$nxte-arrow-down: (1223px, 155px, -1223px, -155px, 15px, 11px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-arrow-down', );
$nxte-arrow-up-green-name: 'nxte-arrow-up-green';
$nxte-arrow-up-green-x: 934px;
$nxte-arrow-up-green-y: 104px;
$nxte-arrow-up-green-offset-x: -934px;
$nxte-arrow-up-green-offset-y: -104px;
$nxte-arrow-up-green-width: 128px;
$nxte-arrow-up-green-height: 128px;
$nxte-arrow-up-green-total-width: 1458px;
$nxte-arrow-up-green-total-height: 878px;
$nxte-arrow-up-green-image: '../images/nxte-sprite.png';
$nxte-arrow-up-green: (934px, 104px, -934px, -104px, 128px, 128px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-arrow-up-green', );
$nxte-bc-bg-name: 'nxte-bc-bg';
$nxte-bc-bg-x: 0px;
$nxte-bc-bg-y: 0px;
$nxte-bc-bg-offset-x: 0px;
$nxte-bc-bg-offset-y: 0px;
$nxte-bc-bg-width: 1458px;
$nxte-bc-bg-height: 104px;
$nxte-bc-bg-total-width: 1458px;
$nxte-bc-bg-total-height: 878px;
$nxte-bc-bg-image: '../images/nxte-sprite.png';
$nxte-bc-bg: (0px, 0px, 0px, 0px, 1458px, 104px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-bc-bg', );
$nxte-bc-frame-name: 'nxte-bc-frame';
$nxte-bc-frame-x: 1195px;
$nxte-bc-frame-y: 168px;
$nxte-bc-frame-offset-x: -1195px;
$nxte-bc-frame-offset-y: -168px;
$nxte-bc-frame-width: 33px;
$nxte-bc-frame-height: 34px;
$nxte-bc-frame-total-width: 1458px;
$nxte-bc-frame-total-height: 878px;
$nxte-bc-frame-image: '../images/nxte-sprite.png';
$nxte-bc-frame: (1195px, 168px, -1195px, -168px, 33px, 34px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-bc-frame', );
$nxte-bubbles-name: 'nxte-bubbles';
$nxte-bubbles-x: 1062px;
$nxte-bubbles-y: 208px;
$nxte-bubbles-offset-x: -1062px;
$nxte-bubbles-offset-y: -208px;
$nxte-bubbles-width: 32px;
$nxte-bubbles-height: 22px;
$nxte-bubbles-total-width: 1458px;
$nxte-bubbles-total-height: 878px;
$nxte-bubbles-image: '../images/nxte-sprite.png';
$nxte-bubbles: (1062px, 208px, -1062px, -208px, 32px, 22px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-bubbles', );
$nxte-cart-icon-name: 'nxte-cart-icon';
$nxte-cart-icon-x: 934px;
$nxte-cart-icon-y: 281px;
$nxte-cart-icon-offset-x: -934px;
$nxte-cart-icon-offset-y: -281px;
$nxte-cart-icon-width: 29px;
$nxte-cart-icon-height: 27px;
$nxte-cart-icon-total-width: 1458px;
$nxte-cart-icon-total-height: 878px;
$nxte-cart-icon-image: '../images/nxte-sprite.png';
$nxte-cart-icon: (934px, 281px, -934px, -281px, 29px, 27px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-cart-icon', );
$nxte-check-orange-name: 'nxte-check-orange';
$nxte-check-orange-x: 1264px;
$nxte-check-orange-y: 155px;
$nxte-check-orange-offset-x: -1264px;
$nxte-check-orange-offset-y: -155px;
$nxte-check-orange-width: 12px;
$nxte-check-orange-height: 11px;
$nxte-check-orange-total-width: 1458px;
$nxte-check-orange-total-height: 878px;
$nxte-check-orange-image: '../images/nxte-sprite.png';
$nxte-check-orange: (1264px, 155px, -1264px, -155px, 12px, 11px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-check-orange', );
$nxte-check-red-name: 'nxte-check-red';
$nxte-check-red-x: 1252px;
$nxte-check-red-y: 155px;
$nxte-check-red-offset-x: -1252px;
$nxte-check-red-offset-y: -155px;
$nxte-check-red-width: 12px;
$nxte-check-red-height: 11px;
$nxte-check-red-total-width: 1458px;
$nxte-check-red-total-height: 878px;
$nxte-check-red-image: '../images/nxte-sprite.png';
$nxte-check-red: (1252px, 155px, -1252px, -155px, 12px, 11px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-check-red', );
$nxte-check-name: 'nxte-check';
$nxte-check-x: 1379px;
$nxte-check-y: 168px;
$nxte-check-offset-x: -1379px;
$nxte-check-offset-y: -168px;
$nxte-check-width: 16px;
$nxte-check-height: 15px;
$nxte-check-total-width: 1458px;
$nxte-check-total-height: 878px;
$nxte-check-image: '../images/nxte-sprite.png';
$nxte-check: (1379px, 168px, -1379px, -168px, 16px, 15px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-check', );
$nxte-clock-name: 'nxte-clock';
$nxte-clock-x: 1287px;
$nxte-clock-y: 155px;
$nxte-clock-offset-x: -1287px;
$nxte-clock-offset-y: -155px;
$nxte-clock-width: 11px;
$nxte-clock-height: 11px;
$nxte-clock-total-width: 1458px;
$nxte-clock-total-height: 878px;
$nxte-clock-image: '../images/nxte-sprite.png';
$nxte-clock: (1287px, 155px, -1287px, -155px, 11px, 11px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-clock', );
$nxte-close-btn-name: 'nxte-close-btn';
$nxte-close-btn-x: 1166px;
$nxte-close-btn-y: 208px;
$nxte-close-btn-offset-x: -1166px;
$nxte-close-btn-offset-y: -208px;
$nxte-close-btn-width: 21px;
$nxte-close-btn-height: 22px;
$nxte-close-btn-total-width: 1458px;
$nxte-close-btn-total-height: 878px;
$nxte-close-btn-image: '../images/nxte-sprite.png';
$nxte-close-btn: (1166px, 208px, -1166px, -208px, 21px, 22px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-close-btn', );
$nxte-close-icon-name: 'nxte-close-icon';
$nxte-close-icon-x: 1276px;
$nxte-close-icon-y: 155px;
$nxte-close-icon-offset-x: -1276px;
$nxte-close-icon-offset-y: -155px;
$nxte-close-icon-width: 11px;
$nxte-close-icon-height: 11px;
$nxte-close-icon-total-width: 1458px;
$nxte-close-icon-total-height: 878px;
$nxte-close-icon-image: '../images/nxte-sprite.png';
$nxte-close-icon: (1276px, 155px, -1276px, -155px, 11px, 11px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-close-icon', );
$nxte-colette-name: 'nxte-colette';
$nxte-colette-x: 0px;
$nxte-colette-y: 471px;
$nxte-colette-offset-x: 0px;
$nxte-colette-offset-y: -471px;
$nxte-colette-width: 260px;
$nxte-colette-height: 365px;
$nxte-colette-total-width: 1458px;
$nxte-colette-total-height: 878px;
$nxte-colette-image: '../images/nxte-sprite.png';
$nxte-colette: (0px, 471px, 0px, -471px, 260px, 365px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-colette', );
$nxte-contact-frame-name: 'nxte-contact-frame';
$nxte-contact-frame-x: 354px;
$nxte-contact-frame-y: 104px;
$nxte-contact-frame-offset-x: -354px;
$nxte-contact-frame-offset-y: -104px;
$nxte-contact-frame-width: 318px;
$nxte-contact-frame-height: 324px;
$nxte-contact-frame-total-width: 1458px;
$nxte-contact-frame-total-height: 878px;
$nxte-contact-frame-image: '../images/nxte-sprite.png';
$nxte-contact-frame: (354px, 104px, -354px, -104px, 318px, 324px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-contact-frame', );
$nxte-debug-toggle-name: 'nxte-debug-toggle';
$nxte-debug-toggle-x: 1121px;
$nxte-debug-toggle-y: 208px;
$nxte-debug-toggle-offset-x: -1121px;
$nxte-debug-toggle-offset-y: -208px;
$nxte-debug-toggle-width: 23px;
$nxte-debug-toggle-height: 22px;
$nxte-debug-toggle-total-width: 1458px;
$nxte-debug-toggle-total-height: 878px;
$nxte-debug-toggle-image: '../images/nxte-sprite.png';
$nxte-debug-toggle: (1121px, 208px, -1121px, -208px, 23px, 22px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-debug-toggle', );
$nxte-envelop-name: 'nxte-envelop';
$nxte-envelop-x: 1238px;
$nxte-envelop-y: 155px;
$nxte-envelop-offset-x: -1238px;
$nxte-envelop-offset-y: -155px;
$nxte-envelop-width: 14px;
$nxte-envelop-height: 11px;
$nxte-envelop-total-width: 1458px;
$nxte-envelop-total-height: 878px;
$nxte-envelop-image: '../images/nxte-sprite.png';
$nxte-envelop: (1238px, 155px, -1238px, -155px, 14px, 11px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-envelop', );
$nxte-footer-top-border-name: 'nxte-footer-top-border';
$nxte-footer-top-border-x: 0px;
$nxte-footer-top-border-y: 864px;
$nxte-footer-top-border-offset-x: 0px;
$nxte-footer-top-border-offset-y: -864px;
$nxte-footer-top-border-width: 1400px;
$nxte-footer-top-border-height: 14px;
$nxte-footer-top-border-total-width: 1458px;
$nxte-footer-top-border-total-height: 878px;
$nxte-footer-top-border-image: '../images/nxte-sprite.png';
$nxte-footer-top-border: (0px, 864px, 0px, -864px, 1400px, 14px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-footer-top-border', );
$nxte-front-rest-bot-name: 'nxte-front-rest-bot';
$nxte-front-rest-bot-x: 0px;
$nxte-front-rest-bot-y: 850px;
$nxte-front-rest-bot-offset-x: 0px;
$nxte-front-rest-bot-offset-y: -850px;
$nxte-front-rest-bot-width: 1400px;
$nxte-front-rest-bot-height: 14px;
$nxte-front-rest-bot-total-width: 1458px;
$nxte-front-rest-bot-total-height: 878px;
$nxte-front-rest-bot-image: '../images/nxte-sprite.png';
$nxte-front-rest-bot: (0px, 850px, 0px, -850px, 1400px, 14px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-front-rest-bot', );
$nxte-front-rest-top-name: 'nxte-front-rest-top';
$nxte-front-rest-top-x: 0px;
$nxte-front-rest-top-y: 836px;
$nxte-front-rest-top-offset-x: 0px;
$nxte-front-rest-top-offset-y: -836px;
$nxte-front-rest-top-width: 1400px;
$nxte-front-rest-top-height: 14px;
$nxte-front-rest-top-total-width: 1458px;
$nxte-front-rest-top-total-height: 878px;
$nxte-front-rest-top-image: '../images/nxte-sprite.png';
$nxte-front-rest-top: (0px, 836px, 0px, -836px, 1400px, 14px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-front-rest-top', );
$nxte-giftbox-name: 'nxte-giftbox';
$nxte-giftbox-x: 1223px;
$nxte-giftbox-y: 104px;
$nxte-giftbox-offset-x: -1223px;
$nxte-giftbox-offset-y: -104px;
$nxte-giftbox-width: 59px;
$nxte-giftbox-height: 51px;
$nxte-giftbox-total-width: 1458px;
$nxte-giftbox-total-height: 878px;
$nxte-giftbox-image: '../images/nxte-sprite.png';
$nxte-giftbox: (1223px, 104px, -1223px, -104px, 59px, 51px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-giftbox', );
$nxte-ideal-name: 'nxte-ideal';
$nxte-ideal-x: 1094px;
$nxte-ideal-y: 208px;
$nxte-ideal-offset-x: -1094px;
$nxte-ideal-offset-y: -208px;
$nxte-ideal-width: 27px;
$nxte-ideal-height: 22px;
$nxte-ideal-total-width: 1458px;
$nxte-ideal-total-height: 878px;
$nxte-ideal-image: '../images/nxte-sprite.png';
$nxte-ideal: (1094px, 208px, -1094px, -208px, 27px, 22px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-ideal', );
$nxte-mastercard-name: 'nxte-mastercard';
$nxte-mastercard-x: 1262px;
$nxte-mastercard-y: 168px;
$nxte-mastercard-offset-x: -1262px;
$nxte-mastercard-offset-y: -168px;
$nxte-mastercard-width: 44px;
$nxte-mastercard-height: 24px;
$nxte-mastercard-total-width: 1458px;
$nxte-mastercard-total-height: 878px;
$nxte-mastercard-image: '../images/nxte-sprite.png';
$nxte-mastercard: (1262px, 168px, -1262px, -168px, 44px, 24px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-mastercard', );
$nxte-menu-cart-frame-name: 'nxte-menu-cart-frame';
$nxte-menu-cart-frame-x: 1062px;
$nxte-menu-cart-frame-y: 104px;
$nxte-menu-cart-frame-offset-x: -1062px;
$nxte-menu-cart-frame-offset-y: -104px;
$nxte-menu-cart-frame-width: 161px;
$nxte-menu-cart-frame-height: 64px;
$nxte-menu-cart-frame-total-width: 1458px;
$nxte-menu-cart-frame-total-height: 878px;
$nxte-menu-cart-frame-image: '../images/nxte-sprite.png';
$nxte-menu-cart-frame: (1062px, 104px, -1062px, -104px, 161px, 64px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-menu-cart-frame', );
$nxte-mobile-menu-icon-name: 'nxte-mobile-menu-icon';
$nxte-mobile-menu-icon-x: 965px;
$nxte-mobile-menu-icon-y: 232px;
$nxte-mobile-menu-icon-offset-x: -965px;
$nxte-mobile-menu-icon-offset-y: -232px;
$nxte-mobile-menu-icon-width: 29px;
$nxte-mobile-menu-icon-height: 29px;
$nxte-mobile-menu-icon-total-width: 1458px;
$nxte-mobile-menu-icon-total-height: 878px;
$nxte-mobile-menu-icon-image: '../images/nxte-sprite.png';
$nxte-mobile-menu-icon: (965px, 232px, -965px, -232px, 29px, 29px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-mobile-menu-icon', );
$nxte-ndb-name: 'nxte-ndb';
$nxte-ndb-x: 1062px;
$nxte-ndb-y: 168px;
$nxte-ndb-offset-x: -1062px;
$nxte-ndb-offset-y: -168px;
$nxte-ndb-width: 61px;
$nxte-ndb-height: 40px;
$nxte-ndb-total-width: 1458px;
$nxte-ndb-total-height: 878px;
$nxte-ndb-image: '../images/nxte-sprite.png';
$nxte-ndb: (1062px, 168px, -1062px, -168px, 61px, 40px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-ndb', );
$nxte-note-name: 'nxte-note';
$nxte-note-x: 1362px;
$nxte-note-y: 168px;
$nxte-note-offset-x: -1362px;
$nxte-note-offset-y: -168px;
$nxte-note-width: 17px;
$nxte-note-height: 17px;
$nxte-note-total-width: 1458px;
$nxte-note-total-height: 878px;
$nxte-note-image: '../images/nxte-sprite.png';
$nxte-note: (1362px, 168px, -1362px, -168px, 17px, 17px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-note', );
$nxte-opfactuur-name: 'nxte-opfactuur';
$nxte-opfactuur-x: 963px;
$nxte-opfactuur-y: 281px;
$nxte-opfactuur-offset-x: -963px;
$nxte-opfactuur-offset-y: -281px;
$nxte-opfactuur-width: 19px;
$nxte-opfactuur-height: 26px;
$nxte-opfactuur-total-width: 1458px;
$nxte-opfactuur-total-height: 878px;
$nxte-opfactuur-image: '../images/nxte-sprite.png';
$nxte-opfactuur: (963px, 281px, -963px, -281px, 19px, 26px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-opfactuur', );
$nxte-paypal-name: 'nxte-paypal';
$nxte-paypal-x: 1055px;
$nxte-paypal-y: 232px;
$nxte-paypal-offset-x: -1055px;
$nxte-paypal-offset-y: -232px;
$nxte-paypal-width: 21px;
$nxte-paypal-height: 25px;
$nxte-paypal-total-width: 1458px;
$nxte-paypal-total-height: 878px;
$nxte-paypal-image: '../images/nxte-sprite.png';
$nxte-paypal: (1055px, 232px, -1055px, -232px, 21px, 25px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-paypal', );
$nxte-plus-icon-name: 'nxte-plus-icon';
$nxte-plus-icon-x: 1161px;
$nxte-plus-icon-y: 168px;
$nxte-plus-icon-offset-x: -1161px;
$nxte-plus-icon-offset-y: -168px;
$nxte-plus-icon-width: 34px;
$nxte-plus-icon-height: 34px;
$nxte-plus-icon-total-width: 1458px;
$nxte-plus-icon-total-height: 878px;
$nxte-plus-icon-image: '../images/nxte-sprite.png';
$nxte-plus-icon: (1161px, 168px, -1161px, -168px, 34px, 34px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-plus-icon', );
$nxte-postnl-name: 'nxte-postnl';
$nxte-postnl-x: 1144px;
$nxte-postnl-y: 208px;
$nxte-postnl-offset-x: -1144px;
$nxte-postnl-offset-y: -208px;
$nxte-postnl-width: 22px;
$nxte-postnl-height: 22px;
$nxte-postnl-total-width: 1458px;
$nxte-postnl-total-height: 878px;
$nxte-postnl-image: '../images/nxte-sprite.png';
$nxte-postnl: (1144px, 208px, -1144px, -208px, 22px, 22px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-postnl', );
$nxte-rest-info-name: 'nxte-rest-info';
$nxte-rest-info-x: 672px;
$nxte-rest-info-y: 104px;
$nxte-rest-info-offset-x: -672px;
$nxte-rest-info-offset-y: -104px;
$nxte-rest-info-width: 262px;
$nxte-rest-info-height: 247px;
$nxte-rest-info-total-width: 1458px;
$nxte-rest-info-total-height: 878px;
$nxte-rest-info-image: '../images/nxte-sprite.png';
$nxte-rest-info: (672px, 104px, -672px, -104px, 262px, 247px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-rest-info', );
$nxte-rest-line-name: 'nxte-rest-line';
$nxte-rest-line-x: 672px;
$nxte-rest-line-y: 351px;
$nxte-rest-line-offset-x: -672px;
$nxte-rest-line-offset-y: -351px;
$nxte-rest-line-width: 548px;
$nxte-rest-line-height: 5px;
$nxte-rest-line-total-width: 1458px;
$nxte-rest-line-total-height: 878px;
$nxte-rest-line-image: '../images/nxte-sprite.png';
$nxte-rest-line: (672px, 351px, -672px, -351px, 548px, 5px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-rest-line', );
$nxte-round-checked-tick-name: 'nxte-round-checked-tick';
$nxte-round-checked-tick-x: 1448px;
$nxte-round-checked-tick-y: 104px;
$nxte-round-checked-tick-offset-x: -1448px;
$nxte-round-checked-tick-offset-y: -104px;
$nxte-round-checked-tick-width: 7px;
$nxte-round-checked-tick-height: 7px;
$nxte-round-checked-tick-total-width: 1458px;
$nxte-round-checked-tick-total-height: 878px;
$nxte-round-checked-tick-image: '../images/nxte-sprite.png';
$nxte-round-checked-tick: (1448px, 104px, -1448px, -104px, 7px, 7px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-round-checked-tick', );
$nxte-search-icon-name: 'nxte-search-icon';
$nxte-search-icon-x: 1207px;
$nxte-search-icon-y: 208px;
$nxte-search-icon-offset-x: -1207px;
$nxte-search-icon-offset-y: -208px;
$nxte-search-icon-width: 13px;
$nxte-search-icon-height: 16px;
$nxte-search-icon-total-width: 1458px;
$nxte-search-icon-total-height: 878px;
$nxte-search-icon-image: '../images/nxte-sprite.png';
$nxte-search-icon: (1207px, 208px, -1207px, -208px, 13px, 16px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-search-icon', );
$nxte-slider-arrows-name: 'nxte-slider-arrows';
$nxte-slider-arrows-x: 934px;
$nxte-slider-arrows-y: 232px;
$nxte-slider-arrows-offset-x: -934px;
$nxte-slider-arrows-offset-y: -232px;
$nxte-slider-arrows-width: 31px;
$nxte-slider-arrows-height: 49px;
$nxte-slider-arrows-total-width: 1458px;
$nxte-slider-arrows-total-height: 878px;
$nxte-slider-arrows-image: '../images/nxte-sprite.png';
$nxte-slider-arrows: (934px, 232px, -934px, -232px, 31px, 49px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-slider-arrows', );
$nxte-star-name: 'nxte-star';
$nxte-star-x: 994px;
$nxte-star-y: 232px;
$nxte-star-offset-x: -994px;
$nxte-star-offset-y: -232px;
$nxte-star-width: 30px;
$nxte-star-height: 27px;
$nxte-star-total-width: 1458px;
$nxte-star-total-height: 878px;
$nxte-star-image: '../images/nxte-sprite.png';
$nxte-star: (994px, 232px, -994px, -232px, 30px, 27px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-star', );
$nxte-thuiswinkel-name: 'nxte-thuiswinkel';
$nxte-thuiswinkel-x: 1282px;
$nxte-thuiswinkel-y: 104px;
$nxte-thuiswinkel-offset-x: -1282px;
$nxte-thuiswinkel-offset-y: -104px;
$nxte-thuiswinkel-width: 51px;
$nxte-thuiswinkel-height: 50px;
$nxte-thuiswinkel-total-width: 1458px;
$nxte-thuiswinkel-total-height: 878px;
$nxte-thuiswinkel-image: '../images/nxte-sprite.png';
$nxte-thuiswinkel: (1282px, 104px, -1282px, -104px, 51px, 50px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-thuiswinkel', );
$nxte-top-menu-active-name: 'nxte-top-menu-active';
$nxte-top-menu-active-x: 1387px;
$nxte-top-menu-active-y: 144px;
$nxte-top-menu-active-offset-x: -1387px;
$nxte-top-menu-active-offset-y: -144px;
$nxte-top-menu-active-width: 47px;
$nxte-top-menu-active-height: 4px;
$nxte-top-menu-active-total-width: 1458px;
$nxte-top-menu-active-total-height: 878px;
$nxte-top-menu-active-image: '../images/nxte-sprite.png';
$nxte-top-menu-active: (1387px, 144px, -1387px, -144px, 47px, 4px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-top-menu-active', );
$nxte-vines-name: 'nxte-vines';
$nxte-vines-x: 1123px;
$nxte-vines-y: 168px;
$nxte-vines-offset-x: -1123px;
$nxte-vines-offset-y: -168px;
$nxte-vines-width: 38px;
$nxte-vines-height: 38px;
$nxte-vines-total-width: 1458px;
$nxte-vines-total-height: 878px;
$nxte-vines-image: '../images/nxte-sprite.png';
$nxte-vines: (1123px, 168px, -1123px, -168px, 38px, 38px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-vines', );
$nxte-vink-name: 'nxte-vink';
$nxte-vink-x: 1187px;
$nxte-vink-y: 208px;
$nxte-vink-offset-x: -1187px;
$nxte-vink-offset-y: -208px;
$nxte-vink-width: 20px;
$nxte-vink-height: 20px;
$nxte-vink-total-width: 1458px;
$nxte-vink-total-height: 878px;
$nxte-vink-image: '../images/nxte-sprite.png';
$nxte-vink: (1187px, 208px, -1187px, -208px, 20px, 20px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-vink', );
$nxte-visa-name: 'nxte-visa';
$nxte-visa-x: 1306px;
$nxte-visa-y: 168px;
$nxte-visa-offset-x: -1306px;
$nxte-visa-offset-y: -168px;
$nxte-visa-width: 56px;
$nxte-visa-height: 17px;
$nxte-visa-total-width: 1458px;
$nxte-visa-total-height: 878px;
$nxte-visa-image: '../images/nxte-sprite.png';
$nxte-visa: (1306px, 168px, -1306px, -168px, 56px, 17px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-visa', );
$nxte-wijn-name: 'nxte-wijn';
$nxte-wijn-x: 1387px;
$nxte-wijn-y: 104px;
$nxte-wijn-offset-x: -1387px;
$nxte-wijn-offset-y: -104px;
$nxte-wijn-width: 61px;
$nxte-wijn-height: 40px;
$nxte-wijn-total-width: 1458px;
$nxte-wijn-total-height: 878px;
$nxte-wijn-image: '../images/nxte-sprite.png';
$nxte-wijn: (1387px, 104px, -1387px, -104px, 61px, 40px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-wijn', );
$nxte-winkel-frame-name: 'nxte-winkel-frame';
$nxte-winkel-frame-x: 0px;
$nxte-winkel-frame-y: 104px;
$nxte-winkel-frame-offset-x: 0px;
$nxte-winkel-frame-offset-y: -104px;
$nxte-winkel-frame-width: 354px;
$nxte-winkel-frame-height: 367px;
$nxte-winkel-frame-total-width: 1458px;
$nxte-winkel-frame-total-height: 878px;
$nxte-winkel-frame-image: '../images/nxte-sprite.png';
$nxte-winkel-frame: (0px, 104px, 0px, -104px, 354px, 367px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-winkel-frame', );
$nxte-winkel-icon-name: 'nxte-winkel-icon';
$nxte-winkel-icon-x: 1228px;
$nxte-winkel-icon-y: 168px;
$nxte-winkel-icon-offset-x: -1228px;
$nxte-winkel-icon-offset-y: -168px;
$nxte-winkel-icon-width: 34px;
$nxte-winkel-icon-height: 32px;
$nxte-winkel-icon-total-width: 1458px;
$nxte-winkel-icon-total-height: 878px;
$nxte-winkel-icon-image: '../images/nxte-sprite.png';
$nxte-winkel-icon: (1228px, 168px, -1228px, -168px, 34px, 32px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-winkel-icon', );
$nxte-winkelwagen-name: 'nxte-winkelwagen';
$nxte-winkelwagen-x: 1333px;
$nxte-winkelwagen-y: 104px;
$nxte-winkelwagen-offset-x: -1333px;
$nxte-winkelwagen-offset-y: -104px;
$nxte-winkelwagen-width: 54px;
$nxte-winkelwagen-height: 46px;
$nxte-winkelwagen-total-width: 1458px;
$nxte-winkelwagen-total-height: 878px;
$nxte-winkelwagen-image: '../images/nxte-sprite.png';
$nxte-winkelwagen: (1333px, 104px, -1333px, -104px, 54px, 46px, 1458px, 878px, '../images/nxte-sprite.png', 'nxte-winkelwagen', );
$spritesheet-width: 1458px;
$spritesheet-height: 878px;
$spritesheet-image: '../images/nxte-sprite.png';
$spritesheet-sprites: ($nxte-achteraf, $nxte-arrow-down, $nxte-arrow-up-green, $nxte-bc-bg, $nxte-bc-frame, $nxte-bubbles, $nxte-cart-icon, $nxte-check-orange, $nxte-check-red, $nxte-check, $nxte-clock, $nxte-close-btn, $nxte-close-icon, $nxte-colette, $nxte-contact-frame, $nxte-debug-toggle, $nxte-envelop, $nxte-footer-top-border, $nxte-front-rest-bot, $nxte-front-rest-top, $nxte-giftbox, $nxte-ideal, $nxte-mastercard, $nxte-menu-cart-frame, $nxte-mobile-menu-icon, $nxte-ndb, $nxte-note, $nxte-opfactuur, $nxte-paypal, $nxte-plus-icon, $nxte-postnl, $nxte-rest-info, $nxte-rest-line, $nxte-round-checked-tick, $nxte-search-icon, $nxte-slider-arrows, $nxte-star, $nxte-thuiswinkel, $nxte-top-menu-active, $nxte-vines, $nxte-vink, $nxte-visa, $nxte-wijn, $nxte-winkel-frame, $nxte-winkel-icon, $nxte-winkelwagen, );
$spritesheet: (1458px, 878px, '../images/nxte-sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
