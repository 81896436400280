/**
	IMPORTANT: These styles apply for screen widths
	ABOVE and INCLUDING the third break-point
	They are NOT LIMITED to a maximum width

	Media query is not needed inside this file
**/

#navbar {

	.logo {
		height: 150px;
		padding-top: 0;

		a {
			margin-left: 0;
			margin-top: 35px;	
			
			.front &,
			.page-giftcards & {
			    margin-bottom: 0;
			}

			.front & {
			    margin-top: 0;
			}
		}
	}

	#main-menu {
		position: static;

		.region-menu {
			padding-top: 46px;
			
			.front & {
				padding-top: 38px;
			}

			#block-giftcard-form-giftcard-small-cart {
				right: -10px;
				top: 48px;
			}

			.block-tuisvinkel {
				right: 15px;
				top: 12px;
			}

			.block-giftcard-header {
				display: block;

				.content {
					padding-right: 190px;
				}

				.field-item {
					text-align: center;
					font-size: 22px;
					color: $color3;

					img {
						width: 16px;
						vertical-align: -2px;
						margin-right: 5px;
					}
				}
			}

			.block-menu-block {
				display: block;

				.content {
					margin-right: 145px;
				}

				.menu {
					text-align: center;
					margin-left: -10px;

					li {
						display: inline-block;
						margin-right: 5px;
						margin-top: 17px;

						&:last-child {
							margin-right: 0;
						}

						a {
							display: inline-block;
							text-decoration: none;
							line-height: 1;
							border: 1px solid transparent;
							transition: border .25s;
							border-radius: 7px;
							padding: 4px;
							font-size: 13px;

							&:hover,
							&.active {
								border-color: $color3;
							}

							&.active {
								font-weight: $bold;
								color: $color3;
							}
						}
					}
				}
			}
		}
	}
}

.highlited-wrapper {
	.page-giftcards & {
		padding: 14px 0px;
	}
}

.node {
	.region-highlited & {
		.field-item {
			div {
				&:nth-child(5),
				&:nth-child(6) {
					width: $container-width-lg / 2;
				}
			}
		}
	}

	&.node-page {
		&.node-page-full {
			> .group-left {
				width: 500px;
			}

			> .group-right {
				width: 330px;
			}

			.block-ndb-small {
			    padding: 25px 16px;

				.left {
					width: auto;
				}

				.right {
					width: auto;
					margin-left: 10px !important;
					margin-top: 20px;

					li {
						margin-bottom: 20px;
						&:last-child {
							margin-bottom: 0;
						}
					}

					p {
						&:last-child {
							display: none;
						}
					}
				}
			}
		}
	}
}

footer {
	.pre_footer1 {

		.container {
			&:before {
				right: 50px;
			}
		}

		.block {
			.content {
				max-width: 240px;
			}
		}
	}
}

.block {
	&.block-views {
		.front .region-highlited & {
			.content {
				width: $container-width-lg;
			}

			.view-content {
				.views-row {
					.node {
						max-width: 346px;
						margin: 0 auto;
					}
				}
			}
		}
	}

	&.block-system {
		.front .region-content & {
			.field-name-body {
				@include clearfix;
				div {
					float: left;
					margin-bottom: 0;
				}

				.left {
					width: 56%;
					margin-right: 4%;
				}

				.right {
					width: 40%;
				}
			}
		}

		.node-type-restaurant & {
			.group-restaurant-info {
				padding: 39px 25px;
			}

			.field-name-field-head-image-detailed {
				width: 52%;
				margin-left: 8%;
			}

			.field-name-field-image {
				.field-item {
					width: 23.5%;

					&:nth-child(2n) {
						margin-left: 0;
					}

					margin-right: 2%;

					&:nth-child(4n) {
						margin-right: 0;
					}
				}
			}
		}

		.zakelijk-page & {
			.delivery-date-zakelijk {
				display: block;
				margin-left: 0;
			}
		}
	}

	&.block-ndb-small {
		.content {
			padding: 25px 16px;
		}

		.right {
			margin-left: 40px;

			p {
				margin-top: 28px;
			}
		}

		ul {
			li {
				background-position: left top;
			}
		}
	}

	&.block-phone-guy {
		.field-item {
			max-width: 360px;
		}
	}

	&.block-zakelijk-slider {
		&.block-zakelijk-slider-2,
		&.block-zakelijk-slider-3 {
			margin-bottom: 0 !important;
		}
	}

	&.block-giftcard-breadcrumbs {
		.content {
			text-align: left;

			div {
				height: auto;
				font-size: 18px;
				margin-right: 0;

				&:after {
					display: none;
				}

				.number {					
					height: auto;
					font-size: 18px;
					width: 22px;
					&:before {
						content: '';
						position: absolute;
						left: 0;
						top: 8px;
						@include sprite($nxte-check-red);
					}
				}

				.text,
				.arrow,
				.number {
					display: inline-block;
					vertical-align: top;
					color: $color3;
				}
				.number {
					&:after {
						content: none;
					}
				}

				.text {
					text-indent: -8px;
					overflow: hidden;
				}

				.arrow {
					margin: 0 11px;
					line-height: 25px;
				}

				&.current {
					.number {
						background: none;
						position: relative;
						&:before {
							content: '';
							position: absolute;
							left: 0;
							top: 6px;
							@include sprite($nxte-check);
						}
						&:after {
							content: none;
						}
					}
				}

				&.current ~ div {
					.number {
						background: none;
						text-indent: 0;
						width: auto;
						&:after {
							content: none;
						}
					}

					.text {
						text-indent: 0;
					}

					.number,
					.text {
						color: $color5;
					}
				}
			}
		}
	}
}

.region {
	&.region-content {
		.zakelijk-page & {
			.block-system,
			.block-phone-guy {
				width: 500px;
			}

			.block-ndb-small {
				position: absolute;
				top: 40px;
				right: 0;
				width: 330px;

				.left {
					width: auto;
				}

				.right {
					width: auto;
					margin-left: 10px;
					margin-top: 20px;

					li {
						margin-bottom: 17px;
						&:last-child {
							margin-bottom: 0;
						}
					}

					p {
						&:last-child {
							display: none;
						}
					}
				}
			}
		}
	}
}

#modalContent {
	.ctools-modal-content {
		height: 80% !important;
	}

	#modal-content {
		form {
			.webform-component--vul-onderstande {
				h2 {
					max-width: 450px;
				}
			}

			.form-item {
				&:nth-child(n) {
					margin-left: 0;
					width: 100%;
				}

				&:nth-child(2),
				&:nth-child(7) {
					width: 34%;
				}

				&:nth-child(3),
				&:nth-child(8) {
					width: 64%;
					margin-left: 2%;
				}

				&:nth-child(4) {
					width: 56%;
				}

				&:nth-child(5),
				&:nth-child(6) {
					width: 20%;
					margin-left: 2%;
				}
			}
		}
	}
}

$giftcard-content-width-lg: 530px;
$giftcard-sidebar-width-lg: 300px;

.page-giftcards {
	.block-giftcard-ndb {
		width: $giftcard-content-width-lg;
	}

	.messages {
		position: absolute;
		width: $giftcard-content-width-lg;
		top: 34px;

		& + .region-content {
			.block:first-child {
				margin-top: 64px;
			}
		}
	}

	.block-system {
		form {
			.form-actions {
				clear: left;
				width: $giftcard-content-width-lg;
			}
		}

		#edit-cart {
			.giftcard-cart {
				.item-list {
					.card-qty {
						margin-left: 0;
						display: block;
					}

					.price {
						position: absolute;
					}
				}
			}
		}

		.giftcard-pane-right {
			float: left;
			width: $giftcard-content-width-lg;

			.giftcard-pane-right-middle {
				.form-item-giftcard-sell-price {
					> label {
						width: 126px;
						line-height: 19px;
					}
				}
			}

			.giftcard-pane-right-right {
				#edit-top {
					.extra-products {
						.giftcard-form-extra-product {
							.giftcard-form-extra-product-right {
								width: 163px;
							}
						}
					}
				}
			}
		}

		.giftcard-pane-cart-sidebar {
			float: right;
			width: $giftcard-sidebar-width-lg;

			.block-giftcard-review {
				.score {
					top: 42px;
				    left: 210px;
				}
			}
		}
	}
}

.page-giftcards-extra {
	#edit-right {
		width: $giftcard-content-width-lg;
		float: left;

		.view {
			.views-row {
				width: 100%;
			}
		}
	}

	.block-system {
		#edit-cart {
			margin-top: 0;
		}
	}

	#edit-cart-sidebar {
		position: relative;
	}

	.sidebar-next-button {
		a {
			width: 100%
		}
	}
}

.page-restaurants {
	.region-content {
		.pager {
			.pager-previous,
			.pager-next {
				display: inline;
				margin-left: 1.5em;
			}
		}
	}
}

.page-giftcards-shipping {
	.block-system {
		.giftcard-pane-right {
			#edit-billing,
			#edit-shipping {
				.personal-pane-left {
					.prefix {
						width: 150px;
						display: block;
						line-height: 40px;
					}

					> .form-type-textfield,
					> .form-wrapper {
						width: 380px;
					}

					> .form-type-textfield,
					> .form-wrapper,
					.prefix {
						float: left
					}

					.form-type-textfield {
						margin: 0 0 10px;
					}

					#edit-address-row-2,
					#edit-address-row-2--2 {
						.form-type-textfield {
							width: 49%;
							float: left;

							&:nth-child(2n) {
								margin-left: 2%;	
							}
						}
					}
				}
			}

			#edit-shipping {
				.personal-pane-left {
					.prefix {
						&:nth-child(6) {
							position: relative;
							line-height: 24px;

							span {
								position: absolute;
								color: $grey;
								font-size: 13px;
								left: 0;
								bottom: -14px;
								font-weight: bold;
							}
						}
					}
				}
			}
		}
	}
}

.page-giftcards-overview {
	.block-system {
		#edit-row-wrapper-top,
		#edit-row-wrapper-bottom {
			@include clearfix;
		}

		#edit-cart {
			.giftcard-cart {
				h3 {
					font-size: 24px;
					font-weight: $bold;
				}
			}
		}

		#edit-right {
			position: relative;

			&:before {
				content: "";
				display: block;
				position: absolute;
				width: 1px;
				height: 91%;
				left: 50%;
				margin-left: -1px;
				background: $grey;
				top: 0;
			}

			> p {
				text-align: right;
				padding-top: 20px;
				border-top: 1px solid $grey;
			}
		}

		#edit-shipping,
		#edit-payment,
		#edit-reference,
		#edit-cart,
		.delivery-date-title,
		.delivery-date-txt,
		#edit-delivery {
			width: 47%;
			float: left;
		}

		.delivery-date-title {
			color: $color3;
		}

		#edit-shipping {
			min-height: 477px;
		}

		#edit-shipping,
		#edit-cart {
			margin-right: 6%;

			.paymanet-summery {
				margin-top: 0;
			}
		}

		#edit-payment {
			margin-top: 0;

			.header {
				color: $color3;
			}
		}

		#edit-reference {
			min-height: 191px;
		}

		#edit-cart {
			padding-right: 20px !important;
		}

		#edit-cart,
		#edit-delivery {
			border-bottom: 0;
		}
	}
}