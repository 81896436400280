#navbar {
	&.fixed {
		padding-top: 0px;
		position: fixed;
		backface-visibility: hidden;
		top: 0;
		left: 0;
		width: 100%;
		background: $white;
		z-index: 1010;
		box-shadow: 0 0 8px 2px rgba($black,.5);
		transition: transform .3s;
		transform: translateY(100%);
		.container {
			> .row {
				min-height: inherit!important;
				#main-menu {
					.nav-toggle {
						top: 32px;
					}
					.region-menu {
					padding-top: 23px;
						#block-giftcard-form-giftcard-small-cart {
							top: 25px;
						}
					}
				}
				.logo {
					height: 95px;
					padding-top: 14px;
				}				
			}
		}
	}
	.logo {
		margin-left: auto;
		margin-right: auto;
		min-height: 1px;
		line-height: 0;

		padding-top: 15px;
		padding-bottom: 15px;

		.front &,
		.page-giftcards & {
			padding-bottom: 0;
		}

		a {
			display: block;
			line-height: 0;
			margin: 0 auto;
			width: 160px;

			.page-giftcards & {
				margin-left: 0;
			}

			.front &,
			.page-giftcards & {
			    margin-bottom: 30px;
			}
		}
	}

	#main-menu {
		position: absolute;
		top: 0;
		left: -15px;
		height: 0;
		width: 100% ;

		.nav-toggle-wrapper {
			.page-giftcards & {
			    display: none !important;
			}

			.nav-toggle {
				display: block;
				width: 24px;
				padding-top: 26px;
				background: url("../images/mobile-menu-icon.png") no-repeat center top/100% auto;
				position: absolute;
				top: 20px;
				left: 0;
				font-size: 8px;
				text-align: center;
				text-transform: uppercase;
				font-weight: bold;
				color: $color3;
				text-decoration: none;
			}

			.region-navigation-xs {
				position: fixed;
				left: -100%;
				top: 0;
				height: 100%;
				background: $white;
				z-index: 99;
				overflow: auto;
				width: 75%;
				min-width: 240px;
				max-width: 340px;	

				& + #menu-overlay {
					position: fixed;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					background: rgba($black, .5);
					z-index: 90;
					display: none;
				}

				.menu {
					padding: 0 15px;

					li {
						font-size: 26px;

						a {
							display: block;
							padding: 4px;
							color: $color3;
							transition: color .3s, background .3s;

							&:hover {
								color: $white;
								background: $color3;
								text-decoration: none;
							}
						}
					}
				}
			}
		}

		.region-menu {
			
			.block-menu-block {
				display: none;
			}

			#block-giftcard-form-giftcard-small-cart {
				position: absolute;
				right: -15px;
				top: 25px;

				a {
					display: block;
					width: 32px;
					height: 30px;
					position: relative;
					text-decoration: none;
					font-weight: 600;
					&:after {
						content: '';
						position: absolute;
						left: 86px;
						top: 0;
						@include sprite($nxte-winkelwagen);
					}

					.small-cart,
					.small-cart-label {
						display: none;
					}

					.small-cart-amount {
						font-size: 16px;
						color: $color3;
						text-align: center;
						padding-top: 1px;
						position: absolute;
						bottom: 26px;
						width: 32px;
						right: 0;
						z-index: 1;
					}
				}
			}

			.block-tuisvinkel,
			.block-giftcard-header {
				display: none;
			}
		}
	}
}